import { Box } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CallIcon from '@mui/icons-material/Call';

const SocialLinks = () => {
    return (
        <Box sx={{
            position: 'fixed',
            top: 'auto',
            bottom: '20px',
            right: '20px',
            backgroundColor: '#072035',
            padding: '20px',
            border: '1px solid rgb(56, 56, 77)',
            width: '20%',
            minWidth: '270px',
            borderRadius: '100px',
        }}>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
                '& a': {
                    display: 'flex'
                },
                '& a:hover svg': {
                    transform: 'rotate(4deg) translateY(-0.1875rem) scale(1.3)',
                    color: '#fff'
                }
            }}>
                <Link to='tel:+971 567503203' target="_blank"><CallIcon sx={{ color: '#fff', transition: '0.5s ease' }} /></Link>
                <Link to="https://wa.me/+971567503203" target="_blank"><WhatsAppIcon sx={{ color: '#fff', transition: '0.5s ease' }} /></Link>
                <Link to={'https://in.linkedin.com/company/1byx?trk=public_profile_experience-item_profile-section-card_image-click'} target="_blank"><LinkedInIcon sx={{ color: '#fff', transition: '0.5s ease' }} /></Link>
                <Link to={'mailto:manaan@1byx.io'} target="_blank"><EmailIcon sx={{ color: '#fff', transition: '0.5s ease' }} /></Link>
            </Box>
        </Box >
    )
}

export default SocialLinks
