import React from 'react'
import { Box, Button, Container, Grid, TextField, Typography, keyframes, useMediaQuery, useTheme } from '@mui/material'
import Header from './Header'
import contact from '../../asset/images/landing-images/contact-bg.png'
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import breadcrumb from '../../asset/images/landing-images/breadcrumb_shape01.png'
import breadcrumb_second from '../../asset/images/landing-images/breadcrumb_shape02.png'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Link } from "react-router-dom";
import SocialLinks from './SocialLinks';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Subscribe from './Subscribe';

const Contact = () => {

    const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

    const updown = keyframes`
  0% {
    transform: rotateX(0deg) translateY(0px);
  }
  50% {
    transform: rotateX(0deg) translateY(-30px);
  }
  100%{
    transform: rotateX(0deg) translateY(0px);
  }
`;

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box>
            <Header />
            <Box sx={{
                backgroundColor: '#011627',
                paddingBottom: { xl: '100px', lg: '100px', md: '100px', sm: '50px', xs: '50px' },
            }}>
                <Box className="animate__animated animate__fadeInUp animate-duration-1.5s animatePreScroll" sx={{
                    padding: { xl: '130px 0 130px', lg: '130px 0 130px', md: '130px 0 130px', sm: '90px 0 90px', xs: '80px 0 100px' },
                    backgroundImage: `url(${contact})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    position: 'relative',
                    '&::before': {
                        content: '""',
                        width: '100%',
                        height: '100%',
                        borderRadius: '0 0 20px 20px',
                        background: 'linear-gradient(0deg, #44a08d 0%, #093637 60%, transparent 90%)',
                        zIndex: '9',
                        top: '0px',
                        position: 'absolute',
                    }
                }}>
                    <Container>
                        <Box sx={{
                            position: 'absolute',
                            zIndex: '999',
                            left: '50%',
                            top: '50%',
                            transform: 'translate(-50% , -50%)',
                        }}>
                            <Typography sx={{
                                textAlign: 'center',
                                fontSize: { xl: '40px', lg: '40px', md: '40px', sm: '30px', xs: '25px' },
                                color: '#fff',
                                marginBottom: '10px'
                            }}>Contact</Typography>
                        </Box>
                    </Container>
                    <Box sx={{
                        position: 'absolute',
                        zIndex: '999',
                        width: '100%',
                        '& img:nth-of-type(1)': {
                            position: 'absolute',
                            right: '0px',
                            left: { xl: '80px', lg: '80px', md: '80px', sm: '80px', xs: '0px' },
                            top: '-100px',
                            transition: 'all 0.3s ease-out 0s',
                            animation: `${updown} 5s linear infinite`,
                        },
                        '& img:nth-of-type(2)': {
                            position: 'absolute',
                            right: { xl: '140px', lg: '140px', md: '140px', sm: '140px', xs: '30px' },
                            left: 'auto',
                            top: { xl: '30px', lg: '30px', md: '30px', sm: '30px', xs: '60px' },
                            transition: 'all 0.3s ease-out 0s',
                            animation: `${spin} 5s linear infinite`,
                        }
                    }}>
                        <img src={breadcrumb} alt='img' />
                        <img src={breadcrumb_second} alt='img' />
                    </Box>
                </Box>
            </Box>

            <Box sx={{
                padding: { xl: "50px", lg: "50px", md: "50px", sm: "20px", xs: '20px' },
                background: "#011627",
                paddingTop: '0px !important'
            }} className="animate__animated animate__fadeInUp animate-duration-1.5s animatePreScroll">

                <Grid container spacing={2}>
                    <Grid sx={{
                        "& a": {
                            textDecoration: "none",
                            height: '100%',
                            "&:hover": {

                                "& h4": {
                                    color: "#2ec4b6",
                                    transition: "ease 1s"
                                }
                            }
                        }
                    }} item lg={3} md={3} sm={6} xs={12}>
                        <Link to='tel:+971 567503203' target="_blank">
                            <Box sx={{
                                "&:hover": {
                                    border: '1px solid #2ec4b6',
                                },
                                backgroundColor: '#072035',
                                padding: '20px 20px 20px 20px',
                                borderRadius: '20px',
                                textAlign: 'center',
                                border: '1px solid #292A37',
                                height: '100%',
                            }}>
                                <Box sx={{

                                    width: '60px',
                                    height: '60px',
                                    border: '1px solid #292A37',
                                    borderRadius: '100%',
                                    margin: '0 auto',
                                    color: '#fff',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                    <CallIcon />
                                </Box>
                                <Box sx={{
                                    "& h4": {
                                        textDecoration: "none",
                                        color: '#fff', fontSize: '20px',
                                        margin: '20px 0px 5px 0',
                                    },
                                }}>
                                    <Typography variant='h4'>Phone</Typography>
                                    <Typography sx={{ color: "#c8c7d8" }}>+971 567503203</Typography>
                                </Box>
                            </Box>
                        </Link>
                    </Grid>
                    <Grid sx={{
                        "& a": {
                            textDecoration: "none",
                            height: '100%',
                            "&:hover": {
                                "& h4": {
                                    color: "#2ec4b6",
                                    transition: "ease 1s"
                                }
                            }
                        }
                    }} item lg={3} md={3} sm={6} xs={12}>
                        <Link to='https://wa.me/+971567503203' target="_blank">
                            <Box sx={{
                                "&:hover": {
                                    border: '1px solid #2ec4b6',
                                },
                                backgroundColor: '#072035',
                                padding: '20px 20px 20px 20px',
                                borderRadius: '20px',
                                textAlign: 'center',
                                height: '100%',
                                border: '1px solid #292A37',
                            }}>
                                <Box sx={{
                                    width: '60px',
                                    height: '60px',
                                    border: '1px solid #292A37',
                                    borderRadius: '100%',
                                    margin: '0 auto',
                                    color: '#fff',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                    <WhatsAppIcon />
                                </Box>
                                <Box sx={{
                                    "& h4": {
                                        textDecoration: "none",
                                        color: '#fff', fontSize: '20px',
                                        margin: '20px 0px 5px 0',
                                    },
                                }}>
                                    <Typography variant='h4'>WhatsApp</Typography>
                                    <Typography sx={{ color: "#c8c7d8" }}>+971 567503203</Typography>
                                </Box>
                            </Box>
                        </Link>
                    </Grid>
                    <Grid sx={{
                        "& a": {
                            textDecoration: "none",
                            height: '100%',
                            "&:hover": {
                                "& h4": {
                                    color: "#2ec4b6",
                                    transition: "ease 1s"
                                }
                            }
                        }
                    }} item lg={3} md={3} sm={6} xs={12}>
                        <Link to='https://www.linkedin.com/company/1byx/?viewAsMember=true' target="_blank">
                            <Box sx={{
                                "&:hover": {
                                    border: '1px solid #2ec4b6',
                                },
                                backgroundColor: '#072035',
                                padding: '20px 20px 20px 20px',
                                borderRadius: '20px',
                                textAlign: 'center',
                                height: '100%',
                                border: '1px solid #292A37',
                            }}>
                                <Box sx={{
                                    width: '60px',
                                    height: '60px',
                                    border: '1px solid #292A37',
                                    borderRadius: '100%',
                                    margin: '0 auto',
                                    color: '#fff',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',

                                }}>
                                    <LinkedInIcon sx={{ color: '#fff' }} />
                                </Box>
                                <Box sx={{
                                    "& h4": {
                                        textDecoration: "none",
                                        color: '#fff', fontSize: '20px',
                                        margin: '20px 0px 5px 0',
                                    },
                                }}>
                                    <Typography variant='h4'>Linkedin</Typography>
                                </Box>
                            </Box>
                        </Link>
                    </Grid>
                    <Grid sx={{
                        "& a": {
                            textDecoration: "none",
                            height: '100%',
                            "&:hover": {
                                "& h4": {
                                    color: "#2ec4b6",
                                    transition: "ease 1s"
                                }
                            }
                        }
                    }} item lg={3} md={3} sm={6} xs={12}>
                        <Link to='mailto:manaan@1byx.io' target="_blank">
                            <Box sx={{
                                "&:hover": {
                                    border: '1px solid #2ec4b6',
                                },
                                backgroundColor: '#072035',
                                padding: '20px 20px 20px 20px',
                                borderRadius: '10px',
                                textAlign: 'center',
                                height: '100%',
                                border: '1px solid #292A37',
                            }}>
                                <Box sx={{
                                    width: '60px',
                                    height: '60px',
                                    border: '1px solid #292A37',
                                    borderRadius: '100%',
                                    margin: '0 auto',
                                    color: '#fff',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',

                                }}>
                                    <EmailIcon />
                                </Box>
                                <Box sx={{
                                    "& h4": {
                                        textDecoration: "none",
                                        color: '#fff', fontSize: '20px',
                                        margin: '20px 0px 5px 0',
                                    },
                                }}>
                                    <Typography variant='h4'>Email</Typography>
                                    <Typography sx={{ color: "#c8c7d8" }}>manaan@1byx.io</Typography>
                                </Box>
                            </Box>
                        </Link>
                    </Grid>
                </Grid>
                <Subscribe />
            </Box>
            {/* {isSmallScreen && <Box sx={{
                backgroundColor: '#29293d',
                padding: '20px',
                borderTop: '1px solid rgb(56, 56, 77)',
            }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '25px',
                    '& a': {
                        display: 'flex'
                    },
                    '& a:hover svg': {
                        transform: 'rotate(4deg) translateY(-0.1875rem) scale(1.3)',
                        color: '#fff'
                    }
                }}>
                    <Link to='tel:+971 567503203' target="_blank"><CallIcon sx={{ color: '#807e98', transition: '0.5s ease' }} /></Link>
                    <Link to={'mailto:manaan@1byx.io'} target="_blank"><WhatsAppIcon sx={{ color: '#807e98', transition: '0.5s ease' }} /></Link>
                    <Link to={'https://in.linkedin.com/company/1byx?trk=public_profile_experience-item_profile-section-card_image-click'} target="_blank"><LinkedInIcon sx={{ color: '#807e98', transition: '0.5s ease' }} /></Link>
                    <Link to={'mailto:manaan@1byx.io'} target="_blank"><EmailIcon sx={{ color: '#807e98', transition: '0.5s ease' }} /></Link>
                </Box>
            </Box>} */}

            {!isSmallScreen && <SocialLinks />}
        </Box >
    )
}

export default Contact
