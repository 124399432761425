import React from 'react';
import Header from './Header';
import { Box, Container, Typography, keyframes } from '@mui/material';
import breadcrumb from "../../asset/images/landing-images/breadcrumb_shape01.png";
import breadcrumb_second from "../../asset/images/landing-images/breadcrumb_shape02.png";
import contact from "../../asset/images/landing-images/contact-bg.png";
import Subscribe from './Subscribe';

const AiAutomation = () => {
    const spin = keyframes`
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  `;

    const updown = keyframes`
  0% {
    transform: rotateX(0deg) translateY(0px);
  }
  50% {
    transform: rotateX(0deg) translateY(-30px);
  }
  100%{
    transform: rotateX(0deg) translateY(0px);
  }
`;
    return (
        <Box>
            <Header />
            <Box
                className="animate__animated animate__fadeInUp animate-duration-1.5s"
                sx={{
                    padding: {
                        xl: "170px 0 195px",
                        lg: "170px 0 195px",
                        md: "170px 0 195px",
                        sm: "150px 0 150px",
                        xs: "140px 0 320px",
                    },
                    backgroundImage: `url(${contact})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    position: "relative",
                    marginBottom: {
                        xl: "100px",
                        lg: "100px",
                        md: "100px",
                        sm: "50px",
                        xs: "50px",
                    },
                    "&::before": {
                        content: '""',
                        width: "100%",
                        height: "100%",
                        borderRadius: "0 0 20px 20px",
                        background:
                            "linear-gradient(0deg, #44a08d 0%, #093637 60%, transparent 90%)",
                        zIndex: "9",
                        top: "0px",
                        position: "absolute",
                    },
                }}
            >
                <Container maxWidth="xl">
                    <Box
                        sx={{
                            position: "absolute",
                            zIndex: "1200",
                            left: "50%",
                            top: "50%",
                            transform: "translate(-50% , -50%)",
                            width: {
                                xl: "90%",
                                lg: "90%",
                                md: "90%",
                                sm: "90%",
                                xs: "90%",
                            },
                            textAlign: "center",
                        }}
                    >
                        <Typography
                            sx={{
                                textAlign: "center",
                                fontSize: {
                                    xl: "40px",
                                    lg: "40px",
                                    md: "40px",
                                    sm: "24px",
                                    xs: "24px",
                                },
                                color: "#fff",
                                marginBottom: "10px",
                            }}
                        >
                            Welcome to 1byx AI Automation Solutions
                        </Typography>
                        <Typography sx={{
                            color: "#fff", fontSize: {
                                xl: "20px",
                                lg: "20px",
                                md: "20px",
                                sm: "16px",
                                xs: "16px",
                            },
                            textAlign: "left"
                        }}>
                            At 1ByX, we're pioneering AI automation solutions that revolutionize business operations across diverse sectors. Our cutting-edge technologies leverage artificial intelligence and machine learning to streamline processes, boost efficiency, and drive innovation. Whether you're a small business looking to optimize workflows or a large enterprise seeking transformative digital solutions, we have the expertise and resources to meet your needs.
                        </Typography>
                    </Box>
                </Container>
                <Box
                    sx={{
                        position: "absolute",
                        zIndex: "999",
                        width: "100%",
                        "& img:nth-of-type(1)": {
                            position: "absolute",
                            right: "0px",
                            left: {
                                xl: "80px",
                                lg: "80px",
                                md: "80px",
                                sm: "80px",
                                xs: "0px",
                            },
                            top: "-100px",
                            transition: "all 0.3s ease-out 0s",
                            animation: `${updown} 5s linear infinite`,
                            zIndex: "-1",
                        },
                        "& img:nth-of-type(2)": {
                            position: "absolute",
                            right: {
                                xl: "140px",
                                lg: "140px",
                                md: "140px",
                                sm: "40px",
                                xs: "30px",
                            },
                            left: "auto",
                            top: {
                                xl: "80px",
                                lg: "80px",
                                md: "30px",
                                sm: "70px",
                                xs: "97px",
                            },
                            width: {
                                xl: "auto",
                                lg: "auto",
                                md: "auto",
                                sm: "auto",
                                xs: "50px",
                            },
                            transition: "all 0.3s ease-out 0s",
                            animation: `${spin} 5s linear infinite`,
                        },
                    }}
                >
                    <img src={breadcrumb} alt="img" />
                    <img src={breadcrumb_second} alt="img" />
                </Box>
            </Box>
            <Box>
                <Container>
                    <Box mb={5}>
                        <Typography fontSize={{
                            xl: "2.5rem",
                            lg: "2.5rem",
                            md: "2.5rem",
                            sm: "1.5rem",
                            xs: "1.5rem",
                        }} color="#2ec4b6">Why Choose 1byx AI Automation?</Typography>
                    </Box>
                    <Box mb={3} >
                        <Typography color="#fff" fontSize={{
                            xl: "2rem",
                            lg: "2rem",
                            md: "2rem",
                            sm: "1rem",
                            xs: "1rem",
                        }}>1.Tailored Solutions:</Typography>
                        <Typography color="#ffffffc7" fontSize={{
                            xl: "1.5rem",
                            lg: "1.5rem",
                            md: "1.5rem",
                            sm: "1rem",
                            xs: "1rem",
                        }}>Our AI automation solutions are customized to address the unique challenges and objectives of your organization. Whether you're in healthcare, finance, manufacturing, or any other industry, we'll work closely with you to develop tailored solutions that deliver tangible results.</Typography>
                    </Box>
                    <Box mb={3}>
                        <Typography color="#fff" fontSize={{
                            xl: "2rem",
                            lg: "2rem",
                            md: "2rem",
                            sm: "1rem",
                            xs: "1rem",
                        }}>2.Enhanced Efficiency:</Typography>
                        <Typography color="#ffffffc7" fontSize={{
                            xl: "1.5rem",
                            lg: "1.5rem",
                            md: "1.5rem",
                            sm: "1rem",
                            xs: "1rem",
                        }}>Say goodbye to repetitive tasks and manual processes. Our AI-powered automation tools streamline operations, allowing your team to focus on high-value tasks that drive growth and innovation. From data entry and analysis to customer support and decision-making, our solutions optimize efficiency across your organization.</Typography>
                    </Box>
                    <Box mb={3}>
                        <Typography color="#fff" fontSize={{
                            xl: "2rem",
                            lg: "2rem",
                            md: "2rem",
                            sm: "1rem",
                            xs: "1rem",
                        }}>3.Predictive Analytics:</Typography>
                        <Typography color="#ffffffc7" fontSize={{
                            xl: "1.5rem",
                            lg: "1.5rem",
                            md: "1.5rem",
                            sm: "1rem",
                            xs: "1rem",
                        }}>Gain valuable insights into market trends, customer behavior, and business performance with our predictive analytics capabilities. Our advanced algorithms analyze vast datasets to identify patterns, trends, and opportunities, empowering you to make data-driven decisions with confidence.
                        </Typography>
                    </Box>
                    <Box mb={3}>
                        <Typography color="#fff" fontSize={{
                            xl: "2rem",
                            lg: "2rem",
                            md: "2rem",
                            sm: "1rem",
                            xs: "1rem",
                        }}>4.Process Optimization:</Typography>
                        <Typography color="#ffffffc7" fontSize={{
                            xl: "1.5rem",
                            lg: "1.5rem",
                            md: "1.5rem",
                            sm: "1rem",
                            xs: "1rem",
                        }}>Maximize productivity and minimize waste with our process optimization solutions. Whether it's inventory management, supply chain optimization, or resource allocation, our AI algorithms optimize processes to reduce costs, improve quality, and enhance overall performance.</Typography>
                    </Box>
                    <Box mb={3}>
                        <Typography color="#fff" fontSize={{
                            xl: "2rem",
                            lg: "2rem",
                            md: "2rem",
                            sm: "1rem",
                            xs: "1rem",
                        }}>5.Personalized Customer Experiences:</Typography>
                        <Typography color="#ffffffc7" fontSize={{
                            xl: "1.5rem",
                            lg: "1.5rem",
                            md: "1.5rem",
                            sm: "1rem",
                            xs: "1rem",
                        }}>Delight your customers with personalized experiences tailored to their preferences and behavior. Our AI-powered customer engagement solutions analyze customer data in real-time to deliver targeted recommendations, offers, and communications, fostering loyalty and driving revenue growth.</Typography>
                    </Box>
                    <Box mb={9}>
                        <Typography color="#fff" fontSize={{
                            xl: "2rem",
                            lg: "2rem",
                            md: "2rem",
                            sm: "1rem",
                            xs: "1rem",
                        }}>6.Scalable and Flexible:</Typography>
                        <Typography color="#ffffffc7" fontSize={{
                            xl: "1.5rem",
                            lg: "1.5rem",
                            md: "1.5rem",
                            sm: "1rem",
                            xs: "1rem",
                        }}>Our AI automation solutions are designed to scale with your business and adapt to evolving needs. Whether you're a startup or a Fortune 500 company, our flexible deployment options and scalable architecture ensure that our solutions grow seamlessly alongside your organization.</Typography>
                    </Box>
                    <Box mb={5}>
                        <Typography variant='h5' color="#2ec4b6" fontSize={{
                            xl: "2.5rem",
                            lg: "2.5rem",
                            md: "2.5rem",
                            sm: "1.5rem",
                            xs: "1.5rem",
                        }}>Experience the Future of Automation with 1byx</Typography>
                    </Box>
                    <Box mb={3}>
                        <Typography color="#ffffffc7" fontSize={{
                            xl: "1.5rem",
                            lg: "1.5rem",
                            md: "1.5rem",
                            sm: "1rem",
                            xs: "1rem",
                        }}>Ready to harness the power of AI automation and transform your business? Partner with 1ByX and unlock new opportunities for growth, efficiency, and innovation. Our team of AI experts is committed to delivering tailored solutions that drive measurable results and create lasting value for your organization.</Typography>
                    </Box>
                    <Box mb={3}>
                        <Typography color="#ffffffc7" fontSize={{
                            xl: "1.5rem",
                            lg: "1.5rem",
                            md: "1.5rem",
                            sm: "1rem",
                            xs: "1rem",
                        }}>Contact us today to learn more about our AI automation solutions and schedule a consultation with our team. Let us help you unlock the full potential of artificial intelligence and revolutionize your business for the digital age.</Typography>
                    </Box >
                    <Box mb={3}>
                        <Typography color="#ffffffc7" fontSize={{
                            xl: "1.5rem",
                            lg: "1.5rem",
                            md: "1.5rem",
                            sm: "1rem",
                            xs: "1rem",
                        }}>Empower Your Business with AI Automation – Partner with 1byx Today!
                        </Typography>
                    </Box >
                </Container >
            </Box >
            <Subscribe />
        </Box>
    )
}

export default AiAutomation
