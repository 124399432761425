import { Box, Button, Container, Grid, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import "../../asset/fonts/stylesheet.css";
import "../../asset/fonts/worksansfont.css";
import dashboardImg from "../../asset/images/landing-images/hero-image.png";
import AlbumRoundedIcon from "@mui/icons-material/AlbumRounded";
import PieChartRoundedIcon from "@mui/icons-material/PieChartRounded";
import SettingsBackupRestoreRoundedIcon from "@mui/icons-material/SettingsBackupRestoreRounded";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import PaidRoundedIcon from "@mui/icons-material/PaidRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import chatImg from "../../asset/images/landing-images/mobile.png";
import Wallets from "../../asset/images/landing-images/wallets.png";
import BracketOrder from "../../asset/images/landing-images/invest_card.png";
import LandingpageSlider from "./LandingpageSlider";
import { Link, useNavigate } from "react-router-dom";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Header from "./Header";
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SocialLinks from "./SocialLinks";
import CallIcon from '@mui/icons-material/Call';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Subscribe from "./Subscribe";

const LandingPage = () => {
  const navigate = useNavigate();

  const keyPoints = [
    {
      title: "Easy Tokenization",
      description: "Quickly list and tokenize properties hassle-free.",
      icon: <AlbumRoundedIcon />
    },
    {
      title: "Global Marketplace",
      description: "Connect with developers and investors worldwide.",
      icon: <SettingsBackupRestoreRoundedIcon />
    },
    {
      title: "No-Code Platform",
      description: "Simplified tokenization process for all users.",
      icon: <HighlightOffRoundedIcon />
    },
    {
      title: "Blockchain Compatibility",
      description: "Supports all major blockchains for versatile assets.",
      icon: <SettingsRoundedIcon />
    },
    {
      title: "Diverse Investments",
      description: "Access new listings, daily rentals, and a trading marketplace.",
      icon: <PaidRoundedIcon />
    },
    {
      title: "User-Friendly Transactions",
      description: "Effortless token purchase with seamless settlement by developers.",
      icon: <PieChartRoundedIcon />
    }
  ];

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));


  return (
    <>
      <Header />
      <Box>
        <Box
          sx={{
            padding: { xl: "70px 0 20px 0", lg: "70px 0 20px 0", md: "70px 0 20px 0", sm: "50px 0 20px 0", xs: "30px 0 20px 0" },
          }}
        >
          <Container maxWidth="xl">
            <Grid container spacing={2} sx={{ alignItems: "center" }}>
              <Grid item xs={12} sm={12} md={7}>
                <Box className="animate__animated animate__fadeInLeft animate-duration-1.5s"
                  sx={{
                    border: "2px solid #2ec4b6",
                    borderRadius: "5px",
                    // height: { xl: '330px', lg: '330px', md: '330px', sm: 'auto', xs: 'auto' },
                    // display: 'flex',
                    // paddingRight: { xl: "40px", lg: "30px", md: "50px", sm: "40px", xs: "0" },
                    "& img": {
                      width: "100%",
                      height: '100%',
                      // flex: '0 0 330px'
                    },
                  }}
                >
                  <img src={dashboardImg} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <Box className="animate__animated animate__fadeInRight animate-duration-1.5s"
                  sx={{
                    "& p": {
                      color: "#807e98",
                    },
                    "& h2": {
                      color: "#fff",
                      fontWeight: "400",
                      lineHeight: "1",
                      marginBottom: "15px",
                      fontSize: {
                        xs: "18px",
                        sm: "25px",
                        md: "45px",
                        lg: "45px",
                      },
                    },
                    "& span": {
                      color: "#807e98",
                      "& em": {
                        color: "#2ec4b6",
                        fontStyle: "normal",
                        fontWeight: "bold",
                      },
                    },
                    "& button": {
                      marginTop: "20px",
                      background: "#2ec4b6",
                      transition: "0.2s all",
                      textTransform: "capitalize",
                      borderRadius: "10px",
                      "&:hover": {
                        background: "#ec9721",
                        transform: "scale(1.05)",
                      },
                    },
                  }}
                >
                  <Typography variant="h2" sx={{ fontFamily: "AR One Sans, sans-serif" }}>Revolutionizing <Typography sx={{ color: '#2ec4b6 !important', }} variant="span">Digital Ownership</Typography> and Investment</Typography>
                  <Typography>
                    1BYX redefines the future of ownership and investment. Our advanced SaaS platform merges blockchain, smart contracts, and a dynamic marketplace. Join us in unlocking a new era of asset tokenization. Experience the future of investment with 1BYX – where innovation meets opportunity.
                  </Typography>
                  <Button onClick={() => navigate("/free-demo")} variant="contained">Demo!</Button>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box
          sx={{
            padding: { xl: "50px 0", lg: "50px 0", md: "50px 0", sm: "0px 0 0px", xs: "0px 0 0px" },
          }}
        >
          <Container maxWidth="xl">
            <Box
              sx={{
                marginBottom: { xl: "50px", lg: "50px", md: "50px", sm: "30px", xs: "30px" },
                "& h2": {
                  color: "#fff",
                  fontSize: {
                    xs: "25px",
                    sm: "30px",
                    md: "40px",
                    lg: "40px",
                  },
                  marginBottom: "10px",
                },
                "& p": {
                  color: "#807e98",
                },
              }}
            >
              <Typography variant="h2" sx={{
                fontFamily: "AR One Sans, sans-serif"
              }}>Digital Ownership, <Typography sx={{ color: '#2ec4b6 !important', }} variant="span">Redefined</Typography></Typography>
              <Typography>
                Where blockchain innovation meets investment excellence
              </Typography>
            </Box>
            <Grid container spacing={2}>
              {keyPoints.map((item, index) => {
                return (
                  <Grid key={index} item xs={12} sm={6} md={4} lg={4}>
                    <AnimationOnScroll animateIn="animate__fadeInUp" animatePreScroll={false} animateOnce={true}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          transition: "filter 0.2s ease-out 0s",
                          marginBottom: "15px",
                          "&:hover": {
                            filter: "brightness(1.3)",
                            "& h4": {
                              color: "#2ec4b6",
                            },
                          },
                          "& span": {
                            width: "68px",
                            height: "68px",
                            flex: "0 0 68px",
                            borderRadius: "100px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginBottom: "15px",
                            marginRight: "20px",
                            "& svg": {
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              borderRadius: "100px",
                              border: "2px solid #2ec4b6",
                              padding: "6px",
                              color: "#fff",
                            },
                          },
                          "& h4": {
                            fontSize: "20px",
                            marginBottom: "5px",
                            fontWeight: "500",
                            color: "#fff",
                          },
                          "& p": {
                            fontSize: "16px",
                            fontWeight: "400",
                            color: "#807e98",
                          },
                        }}
                      >
                        <Typography variant="span">
                          {item.icon}
                        </Typography>
                        <Box>
                          <Typography variant="h4">{item.title}</Typography>
                          <Typography>
                            {item.description}
                          </Typography>
                        </Box>
                      </Box>
                    </AnimationOnScroll>
                  </Grid>
                )
              })}
            </Grid>
          </Container>
        </Box>
        <Box sx={{
          padding: "0 0 50px 0",
        }}>
          <Container maxWidth="xl">
            <LandingpageSlider />
          </Container>
        </Box>
      </Box>

      <Box>
        <Container maxWidth="xl">
          <Box
            sx={{
              marginBottom: { xl: "10px", lg: "10px", md: "10px", sm: "10px", xs: "10px" },
              "& h2": {
                color: "#2ec4b6 !important",
                fontFamily: "AR One Sans, sans-serif",
                fontSize: { xl: "40px", lg: "40px", md: "40px", sm: "30px", xs: '25px' },
                marginBottom: "10px",
              },
              "& p": {
                color: "#807e98",
              },
            }}
          >
            <Typography variant="h2">Features</Typography>
          </Box>
          {/* <Grid container spacing={2}>
            <Grid
              sx={{
                marginBottom: "24px",
              }}
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
            >
              <AnimationOnScroll animateIn="animate__fadeInUp" animatePreScroll={false} animateOnce={true}>
                <Box
                  sx={{
                    background: "#072035",
                    borderRadius: "10px",
                    padding: "20px 20px 0 20px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1em ",
                          "& h4": {
                            fontSize: "20px",
                            fontWeight: "500",
                            color: "#fff",
                          },
                          "& h5": {
                            fontSize: "1rem",
                            color: "#c8c7d8",
                          },
                        }}
                      >
                        <Typography variant="h4">Diverse Investment Opportunities</Typography>
                        <Typography variant="h5">
                          Explore a wide range of real estate properties and investment options tailored to your preferences and risk tolerance.
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                      <Box
                        sx={{
                          overflow: "hidden",
                          // width: "300px",
                          // height: "427px",
                          margin: "0 auto",
                          textAlign: { xl: "right", lg: "right", md: "center", sm: "center", xs: "center" },
                          "& img": {
                            width: { xl: "100%", lg: "100%", md: "40%", sm: "70%", xs: "70%" },
                            // minWidth: { xl: "15.25rem", lg: "15.25rem", md: "15.25rem", sm: "15.25rem", xs: "100%" },
                            width: { xl: "100%", lg: "100%", md: "40%", sm: "70%", xs: "70%" },
                            paddingBottom: "10px",
                            objectFit: "contain"
                            // marginBottom: { xl: "-26%", lg: "-26%", md: "-26%", sm: "-26%", xs: "-118px" },
                          },
                        }}
                      >
                        <img src={chatImg} />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </AnimationOnScroll>
            </Grid>
            <Grid
              sx={{
                marginBottom: "24px",
                '& > div': {
                  height: "100%",
                },
              }}
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
            >
              <AnimationOnScroll animatePreScroll={false} animateIn="animate__fadeInUp" animateOnce={true}>
                <Box
                  sx={{
                    background: "#072035",
                    borderRadius: "10px",
                    padding: "20px",
                    height: "100%",
                    '& iframe': {
                      borderRadius: "10px",
                      border: '0',
                    },
                  }}
                >
                  <iframe
                    width="100%"
                    height="400px"
                    src="https://www.youtube.com/embed/4ViWeNadgLk"
                    title="Course Launch | Nodejs : The Complete Guide to Build Backend Projects [2023]"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </Box>
              </AnimationOnScroll>
            </Grid>
          </Grid> */}
          <Grid container spacing={2}>
            <Grid
              sx={{
                marginBottom: "24px",
                '& > div': {
                  height: "100%",
                },
              }}
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
            >
              <AnimationOnScroll animatePreScroll={false} animateIn="animate__fadeInUp" animateOnce={true}>
                <Box
                  sx={{
                    background: "#072035",
                    borderRadius: "10px",
                    padding: "20px",
                    position: "relative",
                    overflow: "hidden",
                    paddingRight: " 54%",
                    height: "50%",
                    marginBottom: "24px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1em ",
                          "& h4": {
                            fontSize: { xl: "20px", lg: "20px", md: "20px", sm: "18px", xs: "18px" },
                            fontWeight: "500",
                            color: "#fff",
                          },
                          "& h5": {
                            fontSize: "1rem",
                            color: "#c8c7d8",
                          },
                        }}
                      >
                        <Typography variant="h4">Collateralized Borrowing</Typography>
                        <Typography variant="h5">
                          Leverage your property tokens as collateral to borrow funds, unlocking additional financial possibilities and maximizing your investment strategy.
                        </Typography>
                      </Box>
                    </Grid>

                    <Box
                      sx={{
                        overflow: "hidden",
                        textAlign: "right",
                        "& img": {
                          maxWidth: "438px",
                          width: "100%",
                          marginBottom: "0",
                          height: "154px",
                          objectFit: "cover",
                          minWidth: "inherit",
                          objectPosition: "left",
                          position: "absolute",
                          right: "-220px",
                        },
                      }}
                    >
                      <img src={Wallets} />
                    </Box>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    background: "#072035",
                    borderRadius: "10px",
                    padding: "20px",
                    height: 'Calc(50% - 24px)',
                  }}
                >
                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1em ",
                          "& h4": {
                            fontSize: "20px",
                            fontWeight: "500",
                            color: "#fff",
                          },
                          "& h5": {
                            fontSize: "1rem",
                            color: "#c8c7d8",
                          },
                        }}
                      >
                        <Typography variant="h4">Liquidity and Trading</Typography>
                        <Typography variant="h5">
                          Engage in secondary market trading, allowing you to buy, sell, or trade property tokens for increased liquidity and flexibility.
                        </Typography>
                      </Box>
                    </Grid>

                  </Grid>
                </Box>
              </AnimationOnScroll>
            </Grid>
            <Grid
              sx={{
                marginBottom: "24px",
                '& > div': {
                  height: "100%",
                },
              }}
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
            >
              <AnimationOnScroll animatePreScroll={false} animateIn="animate__fadeInUp" animateOnce={true}>
                <Box
                  sx={{
                    background: "#072035",
                    borderRadius: "10px",
                    padding: "20px",
                    height: "100%",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                          height: "100%",
                          "& h4": {
                            fontSize: "20px",
                            marginBottom: "5px",
                            fontWeight: "500",
                            color: "#fff",
                          },
                          "& h5": {
                            fontSize: "1rem",
                            color: "#c8c7d8",
                          },
                        }}
                      >
                        <Typography variant="h4">Seamless Token Purchase</Typography>
                        <Typography variant="h5">
                          Effortlessly buy property tokens through our user-friendly marketplace, making the investment process quick and straightforward.
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <Box
                        sx={{
                          "& img": {
                            maxWidth: "100%",
                            height: "auto",
                          },
                        }}
                      >
                        <img src={BracketOrder} />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </AnimationOnScroll>
            </Grid>
          </Grid>



          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <AnimationOnScroll animatePreScroll={false} animateIn="animate__fadeInUp" animateOnce={true}>
                <Box
                  sx={{
                    background: "#072035",
                    borderRadius: "10px",
                    padding: "20px 20px 20px 20px",
                    overflow: "hidden",
                    height: "100%",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                          height: "100%",
                          position: "relative",
                          paddingRight: "30%",
                          "& h4": {
                            fontSize: { xl: "20px", lg: "20px", md: "20px", sm: "18px", xs: "18px" },
                            marginBottom: "5px",
                            fontWeight: "500",
                            color: "#fff",
                          },
                          "& h5": {
                            fontSize: "1rem",
                            color: "#c8c7d8",
                            height: '65px',
                          },
                        }}
                      >
                        <Typography variant="h4">Transparent Ownership Records</Typography>
                        <Typography variant="h5">
                          Experience the transparency of blockchain technology, providing secure and immutable ownership records for each property token.
                        </Typography>
                        <Box
                          sx={{
                            position: "absolute",
                            right: "-11.25rem",
                            top: " -2.5rem",
                          }}
                        >
                          <img src="https://dydx.exchange/tokens.png" />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </AnimationOnScroll>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <AnimationOnScroll animatePreScroll={false} animateIn="animate__fadeInUp" animateOnce={true}>
                <Box
                  sx={{
                    background: "#072035",
                    borderRadius: "10px",
                    padding: "20px 20px 20px 20px",
                    overflow: "hidden",
                    height: "100%",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                          height: "100%",
                          position: "relative",
                          paddingRight: "30%",
                          "& h4": {
                            fontSize: { xl: "20px", lg: "20px", md: "20px", sm: "18px", xs: "18px" },
                            marginBottom: "5px",
                            fontWeight: "500",
                            color: "#fff",
                          },
                          "& h5": {
                            fontSize: "1rem",
                            color: "#c8c7d8",
                          },
                        }}
                      >
                        <Typography variant="h4">Rewarding Participation</Typography>
                        <Typography variant="h5">
                          Earn rewards for active participation, whether through trading, referrals, or other engagement activities, enhancing the overall investment experience.
                        </Typography>
                        <Box
                          sx={{
                            position: "absolute",
                            right: "-11.25rem",
                            top: " -2.5rem",
                          }}
                        >
                          <img src="https://dydx.exchange/github.png" />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </AnimationOnScroll>
            </Grid>
          </Grid>
        </Container>
      </Box >

      <Subscribe />
      {/* {isSmallScreen && <Box sx={{
        backgroundColor: '#29293d',
        padding: '20px',
        borderTop: '1px solid rgb(56, 56, 77)',
      }}>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '25px',
          '& a': {
            display: 'flex'
          },
          '& a:hover svg': {
            transform: 'rotate(4deg) translateY(-0.1875rem) scale(1.3)',
            color: '#fff'
          }
        }}>
          <Link to='tel:+971 567503203' target="_blank"><CallIcon sx={{ color: '#807e98', transition: '0.5s ease' }} /></Link>
          <Link to={'mailto:manaan@1byx.io'} target="_blank"><WhatsAppIcon sx={{ color: '#807e98', transition: '0.5s ease' }} /></Link>
          <Link to={'https://in.linkedin.com/company/1byx?trk=public_profile_experience-item_profile-section-card_image-click'} target="_blank"><LinkedInIcon sx={{ color: '#807e98', transition: '0.5s ease' }} /></Link>
          <Link to={'mailto:manaan@1byx.io'} target="_blank"><EmailIcon sx={{ color: '#807e98', transition: '0.5s ease' }} /></Link>
        </Box>
      </Box>} */}



      {!isSmallScreen && <SocialLinks />}
    </>
  );
};

export default LandingPage;
