import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Grid, Typography } from '@mui/material';
import AlbumRoundedIcon from "@mui/icons-material/AlbumRounded";
import PieChartRoundedIcon from "@mui/icons-material/PieChartRounded";
import SettingsBackupRestoreRoundedIcon from "@mui/icons-material/SettingsBackupRestoreRounded";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import PaidRoundedIcon from "@mui/icons-material/PaidRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import leftArrow from "../../asset/images/landing-images/angles-left-solid.svg";
import rightArrow from "../../asset/images/landing-images/angles-right-solid.svg";

const LandingpageSlider = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    autoplay: true,
    focusOnSelect: false,
    pauseOnHover: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const investorsFeatures = [
    {
      title: "Diverse Investment Opportunities",
      description: "Explore a wide range of real estate properties and investment options tailored to your preferences and risk tolerance.",
      icon: <PieChartRoundedIcon />
    },
    {
      title: "Seamless Token Purchase",
      description: "Effortlessly buy property tokens through our user-friendly marketplace, making the investment process quick and straightforward.",
      icon: <PaidRoundedIcon />
    },
    {
      title: "Liquidity and Trading",
      description: "Engage in secondary market trading, allowing you to buy, sell, or trade property tokens for increased liquidity and flexibility.",
      icon: <SettingsRoundedIcon />
    },
    {
      title: "Collateralized Borrowing",
      description: "Leverage your property tokens as collateral to borrow funds, unlocking additional financial possibilities and maximizing your investment strategy.",
      icon: <HighlightOffRoundedIcon />
    },
    {
      title: "Transparent Ownership Records",
      description: "Experience the transparency of blockchain technology, providing secure and immutable ownership records for each property token.",
      icon: <SettingsBackupRestoreRoundedIcon />
    },
    {
      title: "Rewarding Participation",
      description: "Earn rewards for active participation, whether through trading, referrals, or other engagement activities, enhancing the overall investment experience.",
      icon: <AlbumRoundedIcon />
    }
  ];

  const brokersFeatures = [
    {
      title: "Efficient Property Management",
      description: "Streamline property management tasks with our platform, ensuring efficient handling of rent collection, tenant management, and property maintenance.",
      icon: <AlbumRoundedIcon />
    },
    {
      title: "Direct Communication with Investors",
      description: "Foster direct communication with investors through our dedicated platform, facilitating seamless interaction and collaboration.",
      icon: <SettingsBackupRestoreRoundedIcon />
    },
    {
      title: "Listing and Tokenization Tools",
      description: "Utilize our no-code platform to easily list properties and tokenize them, providing a hassle-free process for bringing assets to the marketplace.",
      icon: <HighlightOffRoundedIcon />
    },
    {
      title: "Governance Voting",
      description: "Participate in governance voting for buyout offers and potential sales, allowing brokers to actively shape the direction of property management strategies.",
      icon: <SettingsRoundedIcon />
    },
    {
      title: "Automated Rental Income Distribution",
      description: "Automate the distribution of rental income to token holders through our platform, streamlining financial processes and ensuring transparency.",
      icon: <PaidRoundedIcon />
    },
    {
      title: "Marketplace Analytics",
      description: "Access analytics tools for market trends, investor behavior, and property performance, empowering brokers with valuable insights for strategic decision-making.",
      icon: <PieChartRoundedIcon />
    }
  ];

  const developersFeatures = [
    {
      title: "Listing and Tokenization Tools",
      description: "Easily list and tokenize properties with our intuitive interface, providing developers with a seamless process to bring assets to the marketplace.",
      icon: <PaidRoundedIcon />
    },
    {
      title: "Regulatory Compliance Support",
      description: "Receive guidance and support for Special Purpose Vehicle (SPV) creation and legal compliance, ensuring adherence to regulatory requirements.",
      icon: <SettingsRoundedIcon />
    },
    {
      title: "Blockchain Interoperability",
      description: "Leverage our platform's compatibility with major blockchains, enhancing the interoperability of your assets across diverse blockchain ecosystems.",
      icon: <PieChartRoundedIcon />
    },
    {
      title: "User Onboarding and KYC",
      description: "Delegate the user onboarding process and KYC verification to our platform, saving developers time and resources while ensuring compliance.",
      icon: <SettingsBackupRestoreRoundedIcon />
    },
    {
      title: "Streamlined Financial Processes",
      description: "Experience streamlined financial processes with our platform, providing developers with efficient solutions for funds handling and settlements.",
      icon: <AlbumRoundedIcon />
    },
    {
      title: "Marketplace Exposure",
      description: "Gain exposure to a global community of investors and buyers through our marketplace, expanding the reach of your real estate developments and maximizing opportunities.",
      icon: <HighlightOffRoundedIcon />
    }
  ];


  return (
    <>
      <Typography sx={{
        color: "#2ec4b6",
        textAlign: 'center',
        fontSize: {
          xs: "25px",
          sm: "30px",
          md: "40px",
          lg: "40px",
        },
        marginBottom: '15px',
        marginBottom: '50px',
        fontFamily: "AR One Sans, sans-serif",
      }}>1byx for</Typography>
      <Box sx={{
        '& .slick-list': {
          width: '100%',
          maxWidth: '90%',
          margin: '0 auto',
          paddingTop: '20px',
        },
        '& .slick-dots': {
          top: '-35px',
          left: { xl: '30px', lg: '30px', md: '30px', sm: '30px', xs: '0px' },
          bottom: 'inherit',
          zIndex: '0'
        },
        '& .slick-dots li': {
          maxWidth: { xl: '150px', lg: '150px', md: '150px', sm: '150px', xs: '90px' },
          width: '100%',
          margin: '0 auto'
        },
        '& .slick-dots li button': {
          width: '100px',
          height: '40px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: '#072035',
          borderRadius: '10px',
          position: 'relative',
        },
        '& .slick-dots li.slick-active button': {
          background: '#2ec4b6',
        },
        '& .slick-dots li button:after': {
          content: '""',
          width: '0',
          height: '0',
          borderLeft: '10px solid transparent',
          borderRight: '10px solid transparent',
          borderTop: '14px solid transparent',
          position: 'absolute',
          bottom: '-13px',
        },
        '& .slick-dots li.slick-active button:after': {
          borderTopColor: '#2ec4b6',
        },
        '& .slick-dots li button:before': {
          fontSize: { xl: '22px', lg: '20px', md: '22px', sm: '20px', xs: '18px' },
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          fontSize: '16px',
          opacity: '1',
        },
        '& .slick-dots li:nth-of-type(1) button:before': {
          content: '"Investor"',
          fontFamily: 'Roboto',
          color: '#fff'
        },
        '& .slick-dots li:nth-of-type(2) button:before': {
          content: '"Broker"',
          fontFamily: 'Roboto',
          color: '#fff'
        },
        '& .slick-dots li:nth-of-type(3) button:before': {
          content: '"Developer"',
          fontFamily: 'Roboto',
          color: '#fff'
        },
        '& .slick-arrow': {
          width: { lg: '30px', md: '30px', sm: '30px', xs: '26px', },
          height: { lg: '30px', md: '30px', sm: '30px', xs: '26px', },
          zIndex: '1',
          top: 'Calc(50% + 10px)',
          '&:before': {
            fontSize: { lg: '40px', md: '40px', sm: '40px', xs: '26px', },
            opacity: 1,
            color: '#2ec4b6',
          },
        },
        '& .slick-prev': {
          left: { lg: '20px', md: '20px', sm: '10px', xs: '10px', },
        },
        '& .slick-next': {
          right: { lg: '10px', md: '10px', sm: '5px', xs: '5px', },
        },
        '& .slick-next:before': {
          content: "''",
          backgroundImage: `url(${rightArrow})`,
          width: '20px',
          display: 'block',
          height: '20px',
          backgroundPosition: 'center',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
        },
        '& .slick-prev:before': {
          content: "''",
          backgroundImage: `url(${leftArrow})`,
          width: '20px',
          display: 'block',
          height: '20px',
          backgroundPosition: 'center',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
        }
      }}>
        <Slider {...settings}>
          <Box sx={{
            width: '100%',
            padding: '20px 20px 20px 20px',
            borderRadius: '10px',
            color: '#fff',
            cursor: "pointer",
            textAlign: 'center',
            display: 'flex !important',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <Grid container spacing={2}>
              {investorsFeatures?.map((item, index) => {
                return (
                  <Grid key={index} item xs={12} sm={12} md={6} lg={6}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        transition: "filter 0.2s ease-out 0s",
                        marginBottom: "15px",
                        border: '1px dashed #2ec4b6',
                        height: '100%',
                        padding: '10px',
                        borderRadius: '10px',
                        "&:hover": {
                          filter: "brightness(1.3)",
                          "& h4": {
                            color: "#2ec4b6",
                          },
                        },
                        "& span": {
                          width: { lg: '68px', md: '68px', sm: '68px', xs: '40px', },
                          height: { lg: '68px', md: '68px', sm: '68px', xs: '40px', },
                          flex: { lg: '0 0 68px', md: '0 0 68px', sm: '0 0 68px', xs: '0 0 40px', },
                          borderRadius: "100px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: { lg: '15px', md: '15px', sm: '15px', xs: '10px', },
                          marginRight: { lg: '20px', md: '20px', sm: '20px', xs: '10px', },
                          "& svg": {
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "100px",
                            border: "2px solid #2ec4b6",
                            padding: "6px",
                            color: "#fff",
                          },
                        },
                        "& h4": {
                          fontSize: { xl: "20px", lg: "20px", md: "20px", sm: "18px", xs: "14px" },
                          marginBottom: "5px",
                          fontWeight: "500",
                          color: "#fff",
                        },
                        "& p": {
                          fontSize: { xl: "16px", lg: "16px", md: "16px", sm: "16px", xs: "12px" },
                          fontWeight: "400",
                          color: "#807e98",
                        },
                      }}
                    >
                      <Typography variant="span">
                        {item.icon}
                      </Typography>
                      <Box sx={{
                        width: '100%',
                        textAlign: 'start'
                      }}>
                        <Typography variant="h4">{item.title}</Typography>
                        <Typography>
                          {item.description}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                )
              })}
            </Grid>
          </Box>
          <Box sx={{
            width: '100%',
            padding: '20px 20px 20px 20px',
            borderRadius: '10px',
            color: '#fff',
            cursor: "pointer",
            textAlign: 'center',
            display: 'flex  !important',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <Grid container spacing={2}>
              {brokersFeatures?.map((item, index) => {
                return (
                  <Grid item key={index} xs={12} sm={12} md={6} lg={6}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        transition: "filter 0.2s ease-out 0s",
                        marginBottom: "15px",
                        border: '1px dashed #2ec4b6',
                        height: '100%',
                        padding: '10px',
                        borderRadius: '10px',
                        "&:hover": {
                          filter: "brightness(1.3)",
                          "& h4": {
                            color: "#2ec4b6",
                          },
                        },
                        "& span": {
                          width: { lg: '68px', md: '68px', sm: '68px', xs: '40px', },
                          height: { lg: '68px', md: '68px', sm: '68px', xs: '40px', },
                          flex: { lg: '0 0 68px', md: '0 0 68px', sm: '0 0 68px', xs: '0 0 40px', },
                          borderRadius: "100px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: { lg: '15px', md: '15px', sm: '15px', xs: '10px', },
                          marginRight: { lg: '20px', md: '20px', sm: '20px', xs: '10px', },
                          "& svg": {
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "100px",
                            border: "2px solid #2ec4b6",
                            padding: "6px",
                            color: "#fff",
                          },
                        },
                        "& h4": {
                          fontSize: { xl: "20px", lg: "20px", md: "20px", sm: "18px", xs: "14px" },
                          marginBottom: "5px",
                          fontWeight: "500",
                          color: "#fff",
                        },
                        "& p": {
                          fontSize: { xl: "16px", lg: "16px", md: "16px", sm: "16px", xs: "12px" },
                          fontWeight: "400",
                          color: "#807e98",
                        },
                      }}
                    >
                      <Typography variant="span">
                        {item.icon}
                      </Typography>
                      <Box sx={{
                        textAlign: 'start',

                        width: '100%',
                      }}>
                        <Typography variant="h4">{item.title}</Typography>
                        <Typography>
                          {item.description}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                )
              })}
            </Grid>
          </Box>
          <Box sx={{
            width: '100%',
            padding: '20px 20px 20px 20px',
            borderRadius: '10px',
            color: '#fff',
            cursor: "pointer",
            textAlign: 'center',

            display: 'flex  !important',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <Grid container spacing={2}>
              {developersFeatures?.map((item, index) => {
                return (
                  <Grid key={index} item xs={12} sm={12} md={6} lg={6}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        transition: "filter 0.2s ease-out 0s",
                        marginBottom: "15px",
                        border: '1px dashed #2ec4b6',
                        height: '100%',
                        padding: '10px',
                        borderRadius: '10px',
                        "&:hover": {
                          filter: "brightness(1.3)",
                          "& h4": {
                            color: "#2ec4b6",
                          },
                        },
                        "& span": {
                          width: { lg: '68px', md: '68px', sm: '68px', xs: '40px', },
                          height: { lg: '68px', md: '68px', sm: '68px', xs: '40px', },
                          flex: { lg: '0 0 68px', md: '0 0 68px', sm: '0 0 68px', xs: '0 0 40px', },
                          borderRadius: "100px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: { lg: '15px', md: '15px', sm: '15px', xs: '10px', },
                          marginRight: { lg: '20px', md: '20px', sm: '20px', xs: '10px', },
                          "& svg": {
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "100px",
                            border: "2px solid #2ec4b6",
                            padding: "6px",
                            color: "#fff",
                          },
                        },
                        "& h4": {
                          fontSize: { xl: "20px", lg: "20px", md: "20px", sm: "18px", xs: "14px" },
                          marginBottom: "5px",
                          fontWeight: "500",
                          color: "#fff",
                        },
                        "& p": {
                          fontSize: { xl: "16px", lg: "16px", md: "16px", sm: "16px", xs: "12px" },
                          fontWeight: "400",
                          color: "#807e98",
                        },
                      }}
                    >
                      <Typography variant="span">
                        {item.icon}
                      </Typography>
                      <Box sx={{
                        textAlign: 'start',

                        width: '100%',
                      }}>
                        <Typography variant="h4">{item.title}</Typography>
                        <Typography>
                          {item.description}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                )
              })}
            </Grid>
          </Box>
        </Slider>
      </Box>
    </>
  )
}

export default LandingpageSlider
