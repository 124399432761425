import { Box, Button, Container, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useState } from 'react'
import Header from './Header';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CallIcon from '@mui/icons-material/Call';
import { Link } from 'react-router-dom';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import SocialLinks from './SocialLinks';
import axios from 'axios';
import { toast } from 'react-toastify';

const FreeDemo = () => {
    const [loading, setLoading] = useState(false);
    const [formValue, setFormValue] = useState({
        email: "",
        role: "",
        message: ""
    })

    const [errors, setErrors] = useState({
        email: "",
        role: "",
        message: ""
    })

    const handleChange = (event) => {
        setErrors({});
        setFormValue((pre) => ({ ...pre, [event.target.name]: event.target.value }))
    };

    const isValidEmail = (email) => {
        // Regular expression for basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    const handleValidation = () => {
        const tempError = {};
        let isValid = true;

        if (!formValue?.email) {
            tempError.email = "Email address is required."
            isValid = false;
        }
        if (!formValue?.role) {
            tempError.role = "Please select the your role."
            isValid = false;
        }
        if (!formValue?.message) {
            tempError.message = "Please enter message."
            isValid = false;
        }
        if (formValue?.email && !(isValidEmail(formValue.email))) {
            tempError.email = "Email enter valid email."
            isValid = false;
        }
        setErrors(tempError);
        return isValid;
    }

    const handleSubmit = async () => {
        const valid = handleValidation();
        const baseURL = process.env.REACT_APP_BASE_URL
        if (valid) {
            setLoading(true);
            await axios.post(`${baseURL}/user/public_demo/`, formValue).then((response) => {
                if (response.status === 200) {
                    toast.success("Thanks will contact you soon!", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
                setLoading(false)
            }).catch((error) => {
                toast.error(error?.response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setLoading(false)
            })
        }
    };

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box>
            <Header />
            <Box sx={{
                backgroundColor: '#011627',
                padding: { xl: '50px 0px', lg: '50px 0px', md: '50px 0px', sm: '40px 0px', xs: '30px 0px' },
                minHeight: '100vh',
            }}>
                <Container>
                    <Typography sx={{ fontSize: { xl: '28px', lg: '28px', md: '28px', sm: '22px', xs: '22px' }, color: '#fff', textAlign: 'center', marginBottom: '20px' }}>Request a demo</Typography>
                    <Grid container spacing={0} sx={{ justifyContent: 'center' }}>
                        <Grid item lg={6} md={8} sm={12} xs={12} sx={{
                            backgroundColor: '#072035',
                            padding: '40px 20px',
                            borderRadius: '10px'
                        }}>
                            <Grid container spacing={3}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box>
                                        <TextField
                                            name="email"
                                            onChange={handleChange}
                                            sx={{
                                                '& fieldset': {
                                                    borderColor: '#ffffff45 !important',
                                                    borderRadius: '10px',
                                                    color: "#fff !important"
                                                },
                                                ".Mui-error": {
                                                    '& fieldset': {
                                                        borderColor: "#d32f2f !important",
                                                        borderRadius: '10px'
                                                    },
                                                },
                                                '& input': {
                                                    color: '#fff',
                                                    '&::placeholder': {
                                                        textOverflow: 'ellipsis !important',
                                                        color: 'grey !important',
                                                    }
                                                },
                                                '& .MuiFormLabel-root': {
                                                    color: '#fff !important',
                                                },
                                                "& .Mui-error ": {
                                                    '& fieldset': {
                                                        borderColor: '#d32f2f !important',
                                                    },
                                                },
                                            }}
                                            error={errors["email"] ? true : false}
                                            helperText={errors["email"] ? errors["email"] : ""}
                                            label="Email"
                                            fullWidth
                                            placeholder='Your Email'
                                        />
                                    </Box>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <FormControl
                                        fullWidth sx={{
                                            '& fieldset': {
                                                borderColor: '#ffffff45 !important',
                                                borderRadius: '10px'
                                            },
                                            "& .Mui-error ": {
                                                '& fieldset': {
                                                    borderColor: '#d32f2f !important',
                                                },
                                            },
                                            '& label': {
                                                color: '#fff',
                                            },
                                            '& .Mui-focused': {
                                                top: "0px !important",
                                                color: '#fff !important',
                                            },
                                            '& input': {
                                                color: '#fff',
                                            },
                                            '& svg': {
                                                color: '#fff',
                                            },
                                            '& .MuiSelect-select': {
                                                color: '#fff',
                                                paddingLeft: '20px'
                                            },
                                        }}>
                                        <InputLabel id="demo-simple-select-label">I'm a</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="I'm a"
                                            name="role"
                                            error={errors["role"] ? true : false}
                                            onChange={handleChange}
                                            inputProps={{
                                                MenuProps: {
                                                    PaperProps: {
                                                        sx: {
                                                            backgroundColor: "#011627 !important"
                                                        }
                                                    },
                                                    MenuListProps: {
                                                        sx: {
                                                            backgroundColor: "#011627",
                                                            '& li': {
                                                                color: '#fff',
                                                            }
                                                        }
                                                    }
                                                }
                                            }}
                                            value={formValue.role}
                                        >
                                            <MenuItem value={"INVESTOR"}>Investor</MenuItem>
                                            <MenuItem value={"BROKER"}>Broker</MenuItem>
                                            <MenuItem value={"DEVELOPER"}>Developer</MenuItem>
                                        </Select>
                                        {errors["role"] && <FormHelperText error>{errors["role"]}</FormHelperText>}
                                    </FormControl>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box>
                                        <TextField
                                            name="message"
                                            minRows={3}
                                            error={errors["message"] ? true : false}
                                            multiline={true}
                                            helperText={errors["message"] ? errors["message"] : ""}
                                            onChange={handleChange}
                                            sx={{
                                                '& fieldset': {
                                                    borderColor: '#ffffff45 !important',
                                                    borderRadius: '10px',
                                                    color: "#fff !important"
                                                },
                                                ".Mui-error": {
                                                    '& fieldset': {
                                                        borderColor: "#d32f2f !important",
                                                        borderRadius: '10px'
                                                    },
                                                },
                                                '& textarea': {
                                                    color: "#fff !important",
                                                    '&::placeholder': {
                                                        textOverflow: 'ellipsis !important',
                                                        color: 'grey !important',
                                                    }
                                                },
                                                '& .MuiFormLabel-root': {
                                                    color: "#fff !important"
                                                },
                                            }}
                                            label="Message"
                                            fullWidth
                                            placeholder='Enter Your Message'
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            <Button
                                type='submit'
                                onClick={handleSubmit}
                                sx={{
                                    backgroundColor: '#2ec4b6',
                                    textTransform: 'capitalize',
                                    color: '#fff',
                                    margin: '30px auto 0px',
                                    padding: '8px 35px',
                                    display: 'block',
                                    borderRadius: "10px",
                                    '&:hover': {
                                        backgroundColor: '#ec9721',
                                    }
                                }}>Submit</Button>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            {/* {isSmallScreen && <Box sx={{
                backgroundColor: '#29293d',
                padding: '20px',
                borderTop: '1px solid rgb(56, 56, 77)',
            }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '25px',
                    '& a': {
                        display: 'flex'
                    },
                    '& a:hover svg': {
                        transform: 'rotate(4deg) translateY(-0.1875rem) scale(1.3)',
                        color: '#fff'
                    }
                }}>
                    <Link to='tel:+971 567503203' target="_blank"><CallIcon sx={{ color: '#807e98', transition: '0.5s ease' }} /></Link>
                    <Link to={'mailto:manaan@1byx.io'} target="_blank"><WhatsAppIcon sx={{ color: '#807e98', transition: '0.5s ease' }} /></Link>
                    <Link to={'https://in.linkedin.com/company/1byx?trk=public_profile_experience-item_profile-section-card_image-click'} target="_blank"><LinkedInIcon sx={{ color: '#807e98', transition: '0.5s ease' }} /></Link>
                    <Link to={'mailto:manaan@1byx.io'} target="_blank"><EmailIcon sx={{ color: '#807e98', transition: '0.5s ease' }} /></Link>
                </Box>
            </Box>} */}

            {!isSmallScreen && <SocialLinks />}
        </Box>
    )
}

export default FreeDemo
