import './App.css';
import About from './view/landing-page/About';
import Contact from './view/landing-page/Contact';
import FreeDemo from './view/landing-page/FreeDemo';
import LandingPage from './view/landing-page/LandingPage';
import { Route, Routes } from "react-router-dom";
import "animate.css/animate.min.css";
import BlockchainConsulting from './view/landing-page/BlockchainConsulting';
import DefiConsultation from './view/landing-page/DefiConsultation';
import AiAutomation from './view/landing-page/AiAutomation';


function App() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/about-us" element={<About />} />
      <Route path="/free-demo" element={<FreeDemo />} />
      <Route path="/blockchain-consulting" element={<BlockchainConsulting />} />
      <Route path="/defi-consultation" element={<DefiConsultation />} />
      <Route path="/ai-automation" element={<AiAutomation />} />
    </Routes>
  );
}

export default App;


