'use client'
import { AppBar, Box, Button, Container, CssBaseline, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Toolbar, Typography } from '@mui/material';
import React from 'react'
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../../asset/images/landing-images/1byx_logo.jpg";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as Icon } from "../../asset/images/landing-images/1byx-logo-final.svg";
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Header = () => {
  const drawerWidth = 240;

  const navbarItems = [
    {
      name: "Products and services",
      path: "/",
      isSubMenu: true,
      subMenu: [{
        name: "Blockchain consulting",
        path: "/blockchain-consulting"
      },
      {
        name: "DeFi Consultation",
        path: "/defi-consultation"
      },
      {
        name: "AI Automation",
        path: "/ai-automation"
      }
      ]
    },
    {
      name: "About us",
      path: "/about-us"
    },
    {
      name: "Contact us",
      path: "/contact"
    },
    {
      name: "Free demo",
      path: "/free-demo"
    },
  ]

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const location = useLocation();

  const drawer = (
    <Box sx={{
      textAlign: "center"
    }}>
      <List sx={{
        '& li': {
          flexDirection: 'column',
        },
        '& li:nth-of-type(1):hover a + .MuiListItemButton-root': {
          display: 'block',
        },
        // '&:hover':{
        //   '& .MuiListItemButton-root':{
        //     opacity:'1',
        //   visibility:'visible',
        //   top:'40px',
        //   },
        // },
        // '&>:nth-of-type(1):after':{
        //     content:"''",
        //     width: '100%',
        //     height: '50px',
        //     display: 'block',
        //     background: 'transparent',
        //     position: 'absolute',
        //     top: '100%',
        //     left: '0',
        //     right: '0',
        // }
      }}>
        {navbarItems.map((item, index) => (
          <ListItem key={item?.name + 1} disablePadding>
            <ListItemButton
              component={Link}
              to={item.path}
              sx={{
                textAlign: "center",
                color: location.pathname === item.path ? "#2ec4b6" : "#fff !important",
                // textTransform: "capitalize",

              }}
            >
              <ListItemText
                primary={item?.name}
              />
            </ListItemButton>
            {item.isSubMenu && item.subMenu.map((sub, index) => {
              return <ListItemButton key={index}
                component={Link}
                to={sub.path}
                sx={{
                  textAlign: "center",
                  color: location.pathname === sub.path ? "#2ec4b6" : "#fff !important",
                  // textTransform: "capitalize",

                }}
              >
                <ListItemText
                  primary={sub?.name}
                />
              </ListItemButton>
            })
            }
            {/* <Box component={ListItemButton} sx={{
                  backgroundColor: '#072035',
                  padding: '10px',
                  border: '1px solid rgb(56, 56, 77)',
                  borderRadius:'10px',
                  width: '90%',
                  display:'none',
                  // position:'absolute',
                  // top:'100px',
                  // left:'0',
                  // opacity:'0',
                  // visibility:'hidden',
                  transition: '0.5s ease',
                  zIndex:'1',
                  '&:hover':{
                    backgroundColor: '#072035',
                  },
                  '& a':{
                    color:'#fff',
                    display: 'block',
                    textDecoration: 'none',
                    padding: '5px 0',
                    fontSize:'14px',
                    textAlign:'center',
                    '&:hover':{
                      color:'#2ec4b6',
                    },
                  },
                }}>
                  <Link href="#" underline="none">Blockchain consulting</Link>
                  <Link href="#" underline="none">AI Automation</Link>
                </Box> */}
          </ListItem>
        ))}
      </List>
    </Box >
  );

  return (
    <>
      {/* <Box sx={{
        borderBottom: '1px solid rgb(46 196 182 / 30%)',
      }}>
        <Container maxWidth="xl">
          <Box sx={{
            display: { lg: 'flex', md: 'flex', sm: 'flex', xs: 'flex', },
            alignItems: 'center',
            justifyContent: "space-between",
            color: '#fff',
            padding: "15px 0",
            '& p': {
              fontSize: '14px',
              fontWeight: '600',
              '& a': {
                color: '#fff',
                textDecoration: 'none',
              },
            },
          }}>

            <Typography>
              <Link to={'tel:+971 567503203'}>+971 567503203</Link>

            </Typography>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '15px',
              '& a': {
                width: '40px',
                height: '40px',
                flex: '0 0 40px',
                background: ' #305676',
                borderRadius: '100px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '8px',
                '&:hover': {
                  background: '#2ec4b6',
                },
                '& svg': {
                  width: '100%',
                  height: '100%',
                  color: '#fff',
                },
              },
            }}>
              <Link to={'mailto:manaan@1byx.io'} target="_blank"><WhatsAppIcon sx={{ color: '#fff', transition: '0.5s ease' }} /></Link>
              <Link to={'https://in.linkedin.com/company/1byx?trk=public_profile_experience-item_profile-section-card_image-click'} target="_blank"><LinkedInIcon sx={{ color: '#fff', transition: '0.5s ease' }} /></Link>
              <Link to={'mailto:manaan@1byx.io'} target="_blank"><EmailIcon sx={{ color: '#fff', transition: '0.5s ease' }} /></Link>
            </Box>
          </Box>
        </Container>
      </Box> */}
      <Box
        sx={{
          display: "flex",
          padding: '10px 0',
          // borderBottom: '1px solid #4b4a50',
          "& nav": {
            background: "transparent",
            boxShadow: "none",
            position: "relative",
            '& .MuiToolbar-root': {
              paddingLeft: '0',
              paddingRight: '0',
            }
          },
        }}
      >
        <Container maxWidth="xl">
          <CssBaseline />
          <AppBar component="nav">
            <Toolbar>
              <Box
                sx={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  display: { xs: "flex", sm: "none" },
                }}
              >
                <Box sx={{
                  cursor: "pointer",
                  width: "180px",
                  height: "50px",
                  margin: "0",
                  "& svg": {
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  },
                }}>
                  <Link
                    to="/"
                  >
                    {/* <img src={logo} /> */}
                    <Icon />
                  </Link>
                </Box>
                <IconButton
                  color="#fff"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ color: "#fff", display: { sm: "none" } }}
                >
                  <MenuIcon />
                </IconButton>
              </Box>
              <Box sx={{
                display: { xs: "none", sm: "block" },
                cursor: "pointer",
                width: "180px",
                height: "50px",
                "& svg": {
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                },
              }}>
                <Link
                  to="/"
                >
                  {/* <img src={logo} /> */}
                  <Icon />
                </Link>
              </Box>
              <Box
                sx={{
                  marginLeft: "auto",
                  display: { xs: "none", sm: "block" },
                  '& a .MuiListItemButton-root': {
                    display: 'none',
                  },
                  '& a:nth-of-type(1) .MuiListItemButton-root': {
                    display: 'block',
                  },
                  '& a:hover': {
                    '& .MuiListItemButton-root': {
                      opacity: '1',
                      visibility: 'visible',
                      top: '40px',
                    },
                  },
                  '& >a:nth-of-type(1):after': {
                    content: "''",
                    width: '100%',
                    height: '50px',
                    display: 'block',
                    background: 'transparent',
                    position: 'absolute',
                    top: '100%',
                    left: '0',
                    right: '0',
                  }

                  // border: '1px solid #4a4a65',
                  // borderRadius: '100px',
                  // padding: '6px 26px',
                }}
              >
                {navbarItems.map((item) => (
                  <Button
                    key={item?.name}
                    component={Link}
                    to={item?.path}
                    sx={{

                      fontSize: '16px',
                      marginLeft: '30px',
                      position: 'relative',
                      padding: '0',
                      '&:before': {
                        content: '""',
                        width: location.pathname === item.path ? '100%' : '0',
                        height: '2px',
                        background: '#2ec4b6',
                        position: 'absolute',
                        bottom: '0',
                        left: '0',
                        transition: '0.5s all',
                      },
                      '&:hover:before': { width: '100%', },
                      '& .MuiTouchRipple-root': {
                        display: 'none',
                      },
                      color: location.pathname === item.path ? "#2ec4b6" : "#fff !important",
                      textTransform: "capitalize",
                      "&:hover": { color: "#2ec4b6 !important", background: 'transparent', },
                    }}
                  >
                    {item?.name}
                    <Box component={ListItemButton} sx={{
                      backgroundColor: '#072035',
                      padding: '10px',
                      border: '1px solid rgb(56, 56, 77)',
                      borderRadius: '10px',
                      width: 'fit-content',
                      position: 'absolute',
                      top: '100px',
                      left: '0',
                      opacity: '0',
                      visibility: 'hidden',
                      transition: '0.5s ease',
                      zIndex: '1',
                      '&:hover': {
                        backgroundColor: '#072035',
                      },
                      '& a': {
                        color: '#fff',
                        display: 'block',
                        textDecoration: 'none',
                        padding: '5px 0',
                        fontSize: '14px',
                        '&:hover': {
                          color: '#2ec4b6',
                        },
                      },
                    }}>
                      <Link to="/blockchain-consulting" underline="none">Blockchain consulting</Link>
                      <Link to="/defi-consultation" underline="none">DeFi Consultation</Link>
                      <Link to="/ai-automation" underline="none">AI Automation</Link>
                    </Box>
                  </Button>
                ))}
              </Box>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                background: "#072035",
              },
            }}
          >
            {drawer}
          </Drawer>
        </Container>
      </Box >
    </>
  )
}

export default Header
