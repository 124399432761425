import React from 'react';
import Header from './Header';
import { Box, Container, Typography, keyframes } from '@mui/material';
import breadcrumb from "../../asset/images/landing-images/breadcrumb_shape01.png";
import breadcrumb_second from "../../asset/images/landing-images/breadcrumb_shape02.png";
import contact from "../../asset/images/landing-images/contact-bg.png";
import Subscribe from './Subscribe';

const DefiConsultation = () => {

    const spin = keyframes`
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  `;

    const updown = keyframes`
  0% {
    transform: rotateX(0deg) translateY(0px);
  }
  50% {
    transform: rotateX(0deg) translateY(-30px);
  }
  100%{
    transform: rotateX(0deg) translateY(0px);
  }
`;

    return (
        <Box>
            <Header />
            <Box
                className="animate__animated animate__fadeInUp animate-duration-1.5s"
                sx={{
                    padding: {
                        xl: "170px 0 195px",
                        lg: "170px 0 195px",
                        md: "170px 0 195px",
                        sm: "150px 0 150px",
                        xs: "140px 0 320px",
                    },
                    backgroundImage: `url(${contact})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    position: "relative",
                    marginBottom: {
                        xl: "100px",
                        lg: "100px",
                        md: "100px",
                        sm: "50px",
                        xs: "50px",
                    },
                    "&::before": {
                        content: '""',
                        width: "100%",
                        height: "100%",
                        borderRadius: "0 0 20px 20px",
                        background:
                            "linear-gradient(0deg, #44a08d 0%, #093637 60%, transparent 90%)",
                        zIndex: "9",
                        top: "0px",
                        position: "absolute",
                    },
                }}
            >
                <Container maxWidth="xl">
                    <Box
                        sx={{
                            position: "absolute",
                            zIndex: "1200",
                            left: "50%",
                            top: "50%",
                            transform: "translate(-50% , -50%)",
                            width: {
                                xl: "90%",
                                lg: "90%",
                                md: "90%",
                                sm: "90%",
                                xs: "90%",
                            },
                            textAlign: "center",
                        }}
                    >
                        <Typography
                            sx={{
                                textAlign: "center",
                                fontSize: {
                                    xl: "40px",
                                    lg: "40px",
                                    md: "40px",
                                    sm: "24px",
                                    xs: "24px",
                                },
                                color: "#fff",
                                marginBottom: "10px",
                            }}
                        >
                            Welcome to 1ByX DeFi Consultation
                        </Typography>
                        <Typography sx={{
                            color: "#fff", fontSize: {
                                xl: "20px",
                                lg: "20px",
                                md: "20px",
                                sm: "16px",
                                xs: "16px",
                            },
                            textAlign: "left"
                        }}>
                            At 1ByX, we offer comprehensive DeFi consultation services to guide individuals and businesses through the intricate world of decentralized finance (DeFi). Our team of experts provides personalized advice and strategic insights to help you navigate the complexities of DeFi and maximize your investment opportunities.
                        </Typography>
                    </Box>
                </Container>
                <Box
                    sx={{
                        position: "absolute",
                        zIndex: "999",
                        width: "100%",
                        "& img:nth-of-type(1)": {
                            position: "absolute",
                            right: "0px",
                            left: {
                                xl: "80px",
                                lg: "80px",
                                md: "80px",
                                sm: "80px",
                                xs: "0px",
                            },
                            top: "-100px",
                            transition: "all 0.3s ease-out 0s",
                            animation: `${updown} 5s linear infinite`,
                            zIndex: "-1",
                        },
                        "& img:nth-of-type(2)": {
                            position: "absolute",
                            right: {
                                xl: "140px",
                                lg: "140px",
                                md: "140px",
                                sm: "40px",
                                xs: "30px",
                            },
                            left: "auto",
                            top: {
                                xl: "80px",
                                lg: "80px",
                                md: "30px",
                                sm: "70px",
                                xs: "97px",
                            },
                            width: {
                                xl: "auto",
                                lg: "auto",
                                md: "auto",
                                sm: "auto",
                                xs: "50px",
                            },
                            transition: "all 0.3s ease-out 0s",
                            animation: `${spin} 5s linear infinite`,
                        },
                    }}
                >
                    <img src={breadcrumb} alt="img" />
                    <img src={breadcrumb_second} alt="img" />
                </Box>
            </Box>
            <Box>
                <Container>
                    <Box mb={5}>
                        <Typography fontSize={{
                            xl: "2.5rem",
                            lg: "2.5rem",
                            md: "2.5rem",
                            sm: "1.5rem",
                            xs: "1.5rem",
                        }} color="#2ec4b6">Why Choose 1ByX DeFi Consultation?</Typography>
                    </Box>
                    <Box mb={3} >
                        <Typography color="#fff" fontSize={{
                            xl: "2rem",
                            lg: "2rem",
                            md: "2rem",
                            sm: "1rem",
                            xs: "1rem",
                        }}>1.Expert Guidance:</Typography>
                        <Typography color="#ffffffc7" fontSize={{
                            xl: "1.5rem",
                            lg: "1.5rem",
                            md: "1.5rem",
                            sm: "1rem",
                            xs: "1rem",
                        }}>Benefit from the expertise of our seasoned DeFi consultants who have a deep understanding of blockchain technology, smart contracts, and decentralized protocols. Our team will provide tailored guidance based on your specific needs and investment objectives.</Typography>
                    </Box>
                    <Box mb={3}>
                        <Typography color="#fff" fontSize={{
                            xl: "2rem",
                            lg: "2rem",
                            md: "2rem",
                            sm: "1rem",
                            xs: "1rem",
                        }}>2.Customized Solutions:</Typography>
                        <Typography color="#ffffffc7" fontSize={{
                            xl: "1.5rem",
                            lg: "1.5rem",
                            md: "1.5rem",
                            sm: "1rem",
                            xs: "1rem",
                        }}>We understand that every client is unique, which is why we offer customized DeFi solutions tailored to your requirements. Whether you're a novice investor or an experienced DeFi enthusiast, we'll develop a strategy that aligns with your goals and risk appetite.</Typography>
                    </Box>
                    <Box mb={3}>
                        <Typography color="#fff" fontSize={{
                            xl: "2rem",
                            lg: "2rem",
                            md: "2rem",
                            sm: "1rem",
                            xs: "1rem",
                        }}>3.Risk Management:</Typography>
                        <Typography color="#ffffffc7" fontSize={{
                            xl: "1.5rem",
                            lg: "1.5rem",
                            md: "1.5rem",
                            sm: "1rem",
                            xs: "1rem",
                        }}>DeFi can be inherently risky, but our consultation services include robust risk management strategies to safeguard your investments. We'll help you assess and mitigate risks effectively to minimize potential losses and protect your assets.
                        </Typography>
                    </Box>
                    <Box mb={3}>
                        <Typography color="#fff" fontSize={{
                            xl: "2rem",
                            lg: "2rem",
                            md: "2rem",
                            sm: "1rem",
                            xs: "1rem",
                        }}>4.Opportunity Identification:</Typography>
                        <Typography color="#ffffffc7" fontSize={{
                            xl: "1.5rem",
                            lg: "1.5rem",
                            md: "1.5rem",
                            sm: "1rem",
                            xs: "1rem",
                        }}>With our in-depth market analysis and trend forecasting, we identify lucrative investment opportunities within the DeFi space. Whether it's yield farming, decentralized exchanges, or liquidity provision, we'll help you capitalize on emerging trends and maximize your returns.</Typography>
                    </Box>
                    <Box mb={3}>
                        <Typography color="#fff" fontSize={{
                            xl: "2rem",
                            lg: "2rem",
                            md: "2rem",
                            sm: "1rem",
                            xs: "1rem",
                        }}>5.Smart Contract Audit and Development:</Typography>
                        <Typography color="#ffffffc7" fontSize={{
                            xl: "1.5rem",
                            lg: "1.5rem",
                            md: "1.5rem",
                            sm: "1rem",
                            xs: "1rem",
                        }}>Ensure the security and efficiency of your DeFi projects with our smart contract audit and development services. Our experienced developers conduct thorough audits to identify vulnerabilities and optimize smart contracts for optimal performance.</Typography>
                    </Box>
                    <Box mb={3}>
                        <Typography color="#fff" fontSize={{
                            xl: "2rem",
                            lg: "2rem",
                            md: "2rem",
                            sm: "1rem",
                            xs: "1rem",
                        }}>6.Compliance and Regulation:</Typography>
                        <Typography color="#ffffffc7" fontSize={{
                            xl: "1.5rem",
                            lg: "1.5rem",
                            md: "1.5rem",
                            sm: "1rem",
                            xs: "1rem",
                        }}>Navigating the regulatory landscape in DeFi can be challenging, but our consultants stay abreast of regulatory developments and compliance requirements. We'll ensure that your DeFi activities adhere to relevant regulations and best practices, mitigating legal risks and ensuring peace of mind.</Typography>
                    </Box>
                    <Box mb={9}>
                        <Typography color="#fff" fontSize={{
                            xl: "2rem",
                            lg: "2rem",
                            md: "2rem",
                            sm: "1rem",
                            xs: "1rem",
                        }}>7.Continuous Support:</Typography>
                        <Typography color="#ffffffc7" fontSize={{
                            xl: "1.5rem",
                            lg: "1.5rem",
                            md: "1.5rem",
                            sm: "1rem",
                            xs: "1rem",
                        }}>Our consultation services extend beyond initial strategy development, providing ongoing support and guidance as you navigate the dynamic DeFi ecosystem. Whether you have questions, need troubleshooting assistance, or want to explore new opportunities, our team is here to support you every step of the way.</Typography>
                    </Box>

                    <Box mb={5}>
                        <Typography variant='h5' color="#2ec4b6" fontSize={{
                            xl: "2.5rem",
                            lg: "2.5rem",
                            md: "2.5rem",
                            sm: "1.5rem",
                            xs: "1.5rem",
                        }}>Unlock the Potential of DeFi with 1byx</Typography>
                    </Box>
                    <Box mb={3}>
                        <Typography color="#ffffffc7" fontSize={{
                            xl: "1.5rem",
                            lg: "1.5rem",
                            md: "1.5rem",
                            sm: "1rem",
                            xs: "1rem",
                        }}>Ready to embark on your DeFi journey with confidence? Schedule a consultation with 1ByX DeFi Consultation today and gain access to expert insights, personalized strategies, and unparalleled support. Let us help you harness the power of decentralized finance and achieve your financial goals in the new digital economy.</Typography>
                    </Box>
                    <Box mb={3}>
                        <Typography color="#ffffffc7" fontSize={{
                            xl: "1.5rem",
                            lg: "1.5rem",
                            md: "1.5rem",
                            sm: "1rem",
                            xs: "1rem",
                        }}>Contact us now to book your consultation and take the first step towards unlocking the full potential of DeFi.</Typography>
                    </Box >
                </Container >
            </Box >
            <Subscribe />
        </Box>
    )
}

export default DefiConsultation
