import React from 'react';
import Header from './Header';
import { Box, Container, Typography, keyframes } from '@mui/material';
import breadcrumb from "../../asset/images/landing-images/breadcrumb_shape01.png";
import breadcrumb_second from "../../asset/images/landing-images/breadcrumb_shape02.png";
import contact from "../../asset/images/landing-images/contact-bg.png";
import Subscribe from './Subscribe';

const BlockchainConsulting = () => {

    const spin = keyframes`
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  `;

    const updown = keyframes`
  0% {
    transform: rotateX(0deg) translateY(0px);
  }
  50% {
    transform: rotateX(0deg) translateY(-30px);
  }
  100%{
    transform: rotateX(0deg) translateY(0px);
  }
`;
    return (
        <Box>
            <Header />
            <Box
                className="animate__animated animate__fadeInUp animate-duration-1.5s"
                sx={{
                    padding: {
                        xl: "170px 0 195px",
                        lg: "170px 0 195px",
                        md: "170px 0 195px",
                        sm: "150px 0 150px",
                        xs: "140px 0 320px",
                    },
                    backgroundImage: `url(${contact})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    position: "relative",
                    marginBottom: {
                        xl: "100px",
                        lg: "100px",
                        md: "100px",
                        sm: "50px",
                        xs: "50px",
                    },
                    "&::before": {
                        content: '""',
                        width: "100%",
                        height: "100%",
                        borderRadius: "0 0 20px 20px",
                        background:
                            "linear-gradient(0deg, #44a08d 0%, #093637 60%, transparent 90%)",
                        zIndex: "9",
                        top: "0px",
                        position: "absolute",
                    },
                }}
            >
                <Container maxWidth="xl">
                    <Box
                        sx={{
                            position: "absolute",
                            zIndex: "1200",
                            left: "50%",
                            top: "50%",
                            transform: "translate(-50% , -50%)",
                            width: {
                                xl: "90%",
                                lg: "90%",
                                md: "90%",
                                sm: "90%",
                                xs: "90%",
                            },
                            textAlign: "center",
                        }}
                    >
                        <Typography
                            sx={{
                                textAlign: "center",
                                fontSize: {
                                    xl: "40px",
                                    lg: "40px",
                                    md: "40px",
                                    sm: "24px",
                                    xs: "24px",
                                },
                                color: "#fff",
                                marginBottom: "10px",
                            }}
                        >
                            Welcome to 1byx Blockchain Consulting
                        </Typography>
                        <Typography sx={{
                            color: "#fff", fontSize: {
                                xl: "20px",
                                lg: "20px",
                                md: "20px",
                                sm: "16px",
                                xs: "16px",
                            },
                            textAlign: "left"
                        }}>
                            At 1byx, we understand the transformative potential of blockchain technology across industries. Our blockchain consulting service is designed to guide businesses through the complexities of blockchain adoption, empowering them to leverage this revolutionary technology effectively.
                        </Typography>
                    </Box>
                </Container>
                <Box
                    sx={{
                        position: "absolute",
                        zIndex: "999",
                        width: "100%",
                        "& img:nth-of-type(1)": {
                            position: "absolute",
                            right: "0px",
                            left: {
                                xl: "80px",
                                lg: "80px",
                                md: "80px",
                                sm: "80px",
                                xs: "0px",
                            },
                            top: "-100px",
                            transition: "all 0.3s ease-out 0s",
                            animation: `${updown} 5s linear infinite`,
                            zIndex: "-1",
                        },
                        "& img:nth-of-type(2)": {
                            position: "absolute",
                            right: {
                                xl: "140px",
                                lg: "140px",
                                md: "140px",
                                sm: "40px",
                                xs: "30px",
                            },
                            left: "auto",
                            top: {
                                xl: "80px",
                                lg: "80px",
                                md: "30px",
                                sm: "70px",
                                xs: "97px",
                            },
                            width: {
                                xl: "auto",
                                lg: "auto",
                                md: "auto",
                                sm: "auto",
                                xs: "50px",
                            },
                            transition: "all 0.3s ease-out 0s",
                            animation: `${spin} 5s linear infinite`,
                        },
                    }}
                >
                    <img src={breadcrumb} alt="img" />
                    <img src={breadcrumb_second} alt="img" />
                </Box>
            </Box>
            <Box>
                <Container>
                    <Box mb={5}>
                        <Typography fontSize={{
                            xl: "2.5rem",
                            lg: "2.5rem",
                            md: "2.5rem",
                            sm: "1.5rem",
                            xs: "1.5rem",
                        }} color="#2ec4b6">Why Choose 1ByX Blockchain Consulting?</Typography>
                    </Box>
                    <Box mb={3} >
                        <Typography color="#fff" fontSize={{
                            xl: "2rem",
                            lg: "2rem",
                            md: "2rem",
                            sm: "1rem",
                            xs: "1rem",
                        }}>1.Expert Guidance:</Typography>
                        <Typography color="#ffffffc7" fontSize={{
                            xl: "1.5rem",
                            lg: "1.5rem",
                            md: "1.5rem",
                            sm: "1rem",
                            xs: "1rem",
                        }}> Our team of seasoned blockchain experts brings years of experience and in-depth knowledge to the table. Whether you're exploring blockchain for the first time or seeking to optimize existing implementations, we provide tailored guidance every step of the way.</Typography>
                    </Box>
                    <Box mb={3}>
                        <Typography color="#fff" fontSize={{
                            xl: "2rem",
                            lg: "2rem",
                            md: "2rem",
                            sm: "1rem",
                            xs: "1rem",
                        }}>2.Customized Solutions:</Typography>
                        <Typography color="#ffffffc7" fontSize={{
                            xl: "1.5rem",
                            lg: "1.5rem",
                            md: "1.5rem",
                            sm: "1rem",
                            xs: "1rem",
                        }}>We believe in a one-size-does-not-fit-all approach. Our consulting services are customized to align with your unique business objectives, ensuring that you derive maximum value from blockchain technology.</Typography>
                    </Box>
                    <Box mb={3}>
                        <Typography color="#fff" fontSize={{
                            xl: "2rem",
                            lg: "2rem",
                            md: "2rem",
                            sm: "1rem",
                            xs: "1rem",
                        }}>3.Comprehensive Support:</Typography>
                        <Typography color="#ffffffc7" fontSize={{
                            xl: "1.5rem",
                            lg: "1.5rem",
                            md: "1.5rem",
                            sm: "1rem",
                            xs: "1rem",
                        }}>From strategy formulation to implementation and beyond, we offer end-to-end support to help you navigate the entire blockchain journey seamlessly. Whether you need assistance with smart contract development, tokenization, or blockchain integration, we've got you covered.
                        </Typography>
                    </Box>
                    <Box mb={9}>
                        <Typography color="#fff" fontSize={{
                            xl: "2rem",
                            lg: "2rem",
                            md: "2rem",
                            sm: "1rem",
                            xs: "1rem",
                        }}>4.Strategic Partnerships:</Typography>
                        <Typography color="#ffffffc7" fontSize={{
                            xl: "1.5rem",
                            lg: "1.5rem",
                            md: "1.5rem",
                            sm: "1rem",
                            xs: "1rem",
                        }}>As a trusted partner, we work closely with you to identify opportunities for blockchain integration that drive innovation and fuel growth. Our collaborative approach ensures that your blockchain initiatives are aligned with your long-term vision and objectives.</Typography>
                    </Box>

                    <Box mb={5}>
                        <Typography variant='h5' color="#2ec4b6" fontSize={{
                            xl: "2.5rem",
                            lg: "2.5rem",
                            md: "2.5rem",
                            sm: "1.5rem",
                            xs: "1.5rem",
                        }}>Unlock the Power of Blockchain with 1byx</Typography>
                    </Box>
                    <Box mb={3}>
                        <Typography color="#ffffffc7" fontSize={{
                            xl: "1.5rem",
                            lg: "1.5rem",
                            md: "1.5rem",
                            sm: "1rem",
                            xs: "1rem",
                        }}>Ready to harness the potential of blockchain technology for your business? Partner with 1ByX Blockchain Consulting and embark on a journey of innovation, efficiency, and competitive advantage.</Typography>
                    </Box>
                    <Box mb={3}>
                        <Typography color="#ffffffc7" fontSize={{
                            xl: "1.5rem",
                            lg: "1.5rem",
                            md: "1.5rem",
                            sm: "1rem",
                            xs: "1rem",
                        }}>Contact us today to schedule a consultation and take the first step towards realizing your blockchain ambitions.</Typography>
                    </Box >
                </Container >
            </Box >
            <Subscribe />
        </Box >
    )
};

export default BlockchainConsulting;
