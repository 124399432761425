import React from "react";
import Header from "./Header";
import {
  Box,
  Container,
  Grid,
  Typography,
  keyframes,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import contact from "../../asset/images/landing-images/contact-bg.png";
// import aboutus from '../../asset/images/landing-images/about-us.jpg'
import aboutus from "../../asset/images/landing-images/businessman-3300907.jpg";
import teamimg from "../../asset/images/landing-images/team_img02.png";
import mananAnsari from "../../asset/images/landing-images/manan.png";
import madhupMudiraj from "../../asset/images/landing-images/madhup_mudiraj.png";
import imranAnsari from "../../asset/images/landing-images/imran_ansari.png";
import kabirBawa from "../../asset/images/landing-images/kabir_bawa.png";
// import ruheeNazir from "../../asset/images/landing-images/ruhee_nazir.png";
import ruheeNazir from "../../asset/images/landing-images/ruhee_nazir.jpeg";
import breadcrumb from "../../asset/images/landing-images/breadcrumb_shape01.png";
import breadcrumb_second from "../../asset/images/landing-images/breadcrumb_shape02.png";
import linkedInImage from "../../asset/images/landing-images/linkedin-in.svg";
import { Link } from "react-router-dom";
import SocialLinks from "./SocialLinks";
import womenImage from "../../asset/images/landing-images/women-member.jpg";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CallIcon from "@mui/icons-material/Call";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { AnimationOnScroll } from "react-animation-on-scroll";

const About = () => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

  const updown = keyframes`
  0% {
    transform: rotateX(0deg) translateY(0px);
  }
  50% {
    transform: rotateX(0deg) translateY(-30px);
  }
  100%{
    transform: rotateX(0deg) translateY(0px);
  }
`;

  const teamMembers = [
    {
      name: "Manaan Ansari",
      title: "Tech Enthusiast",
      linkedin: "https://www.linkedin.com/in/manaanansari/",
      dp: mananAnsari,
    },
    {
      name: "Madhup Mudiraj",
      title: "Product Specialist",
      linkedin: "https://au.linkedin.com/in/maddymudiraj/",
      dp: madhupMudiraj,
    },
    {
      name: "Imran Ansari",
      title: "Tech Visionary",
      linkedin: "https://www.linkedin.com/in/imraninkbrang/",
      dp: imranAnsari,
    },
    {
      name: "Kabir Bawa",
      title: "Finance Maestro",
      linkedin: "https://www.linkedin.com/in/kabeerbawa/",
      dp: kabirBawa,
    },
    {
      name: "Ruhee Nazir",
      title: "Sales Strategist and Operations Expert",
      linkedin: "https://www.linkedin.com/in/ruhee-nazir/",
      dp: ruheeNazir,
    },
  ];

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box>
      <Header />
      <Box
        sx={{
          // padding: { xl: "50px", lg: "50px", md: "50px", sm: "20px", xs: '20px' },
          backgroundColor: "#011627",
          paddingTop: "0px !important",
        }}
      >
        <Box
          className="animate__animated animate__fadeInUp animate-duration-1.5s"
          sx={{
            padding: {
              xl: "170px 0 195px",
              lg: "170px 0 195px",
              md: "170px 0 195px",
              sm: "150px 0 150px",
              xs: "140px 0 150px",
            },
            backgroundImage: `url(${contact})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            position: "relative",
            marginBottom: {
              xl: "100px",
              lg: "100px",
              md: "100px",
              sm: "50px",
              xs: "50px",
            },
            "&::before": {
              content: '""',
              width: "100%",
              height: "100%",
              borderRadius: "0 0 20px 20px",
              background:
                "linear-gradient(0deg, #44a08d 0%, #093637 60%, transparent 90%)",
              zIndex: "9",
              top: "0px",
              position: "absolute",
            },
          }}
        >
          <Container maxWidth="xl">
            <Box
              sx={{
                position: "absolute",
                zIndex: "1200",
                left: "50%",
                top: "50%",
                transform: "translate(-50% , -50%)",
                width: {
                  xl: "90%",
                  lg: "90%",
                  md: "90%",
                  sm: "90%",
                  xs: "90%",
                },
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: {
                    xl: "40px",
                    lg: "40px",
                    md: "40px",
                    sm: "30px",
                    xs: "25px",
                  },
                  color: "#fff",
                  marginBottom: "10px",
                }}
              >
                About us
              </Typography>
              <Typography sx={{ color: "#fff", fontSize: "20px" }}>
                Welcome to 1byx, where innovation meets practicality, and the
                future of asset tokenization is in your hands.
              </Typography>
            </Box>
          </Container>
          <Box
            sx={{
              position: "absolute",
              zIndex: "999",
              width: "100%",
              "& img:nth-of-type(1)": {
                position: "absolute",
                right: "0px",
                left: {
                  xl: "80px",
                  lg: "80px",
                  md: "80px",
                  sm: "80px",
                  xs: "0px",
                },
                top: "-100px",
                transition: "all 0.3s ease-out 0s",
                animation: `${updown} 5s linear infinite`,
                zIndex: "-1",
              },
              "& img:nth-of-type(2)": {
                position: "absolute",
                right: {
                  xl: "140px",
                  lg: "140px",
                  md: "140px",
                  sm: "40px",
                  xs: "30px",
                },
                left: "auto",
                top: {
                  xl: "80px",
                  lg: "80px",
                  md: "30px",
                  sm: "70px",
                  xs: "97px",
                },
                width: {
                  xl: "auto",
                  lg: "auto",
                  md: "auto",
                  sm: "auto",
                  xs: "50px",
                },
                transition: "all 0.3s ease-out 0s",
                animation: `${spin} 5s linear infinite`,
              },
            }}
          >
            <img src={breadcrumb} alt="img" />
            <img src={breadcrumb_second} alt="img" />
          </Box>
        </Box>
        <Container maxWidth="xl">
          <Grid container spacing={2} sx={{ alignItems: "center" }}>
            <Grid item lg={6} md={6} sm={12}>
              <Box
                className="animate__animated animate__fadeInLeft animate-duration-1.5s animatePreScroll"
                sx={{
                  width: "100%",
                  height: "100%",
                  position: "relative",
                  borderTopRightRadius: "10px",
                  borderBottomRightRadius: "10px",
                  "& img": {
                    width: "95%",
                    height: "100%",
                    objectFit: "contain",
                    borderTopRightRadius: "10px",
                    borderBottomRightRadius: "10px",
                    // borderRadius: { xl: '0px', lg: '0px', md: '0px', sm: '10px', xs: '10px' },
                  },
                  "&::before": {
                    content: "''",
                    width: "95%",
                    height: "98%",
                    position: "absolute",
                    borderTopRightRadius: "10px",
                    borderBottomRightRadius: "170px",
                    background:
                      "linear-gradient(0deg, #44a08d00 -50%, #093637 150%, transparent 90%)",
                    position: "absolute",
                  },
                }}
              >
                <img src={aboutus} />
              </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={12}>
              <Box className="animate__animated animate__fadeInRight animate-duration-1.5s animatePreScroll">
                <Typography
                  sx={{
                    color: "#2ec4b6",
                    marginBottom: "10px",
                    fontSize: "30px",
                  }}
                  variant="h3"
                >
                  Our story
                </Typography>
                <Typography
                  sx={{
                    color: "#c8c7d8",
                    marginBottom: "15px",
                    fontSize: "20px",
                    lineHeight: "28px",
                  }}
                  variant="h5"
                >
                  In the dynamic intersection of technology and real estate,
                  1byx was born from the collaborative minds of our diverse
                  team. Each member, with backgrounds ranging from IT,
                  blockchain, SaaS, AI, to finance, brought unique skills to the
                  table. Our shared vision led to the creation of a
                  groundbreaking SaaS platform.{" "}
                </Typography>
                <Typography
                  sx={{
                    color: "#c8c7d8",
                    marginBottom: "15px",
                    fontSize: "20px",
                    lineHeight: "28px",
                  }}
                  variant="h5"
                >
                  Today, 1byx doesn't just tokenize real estate; it empowers
                  developers, connects property managers, and unlocks global
                  investment opportunities. Our journey is a story of crafting a
                  comprehensive solution that reshapes digital ownership.
                  Welcome to 1byx, where innovation and services redefine the
                  landscape of asset tokenization.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>

        <AnimationOnScroll
          animatePreScroll={false}
          animateIn="animate__fadeInUp"
          animateOnce={true}
        >
          <Box
            sx={{
              paddingBottom: "50px",
            }}
          >
            <Container maxWidth="xl">
              <Typography
                sx={{
                  textAlign: "center",
                  color: "#fff",
                  fontSize: {
                    xl: "30px",
                    lg: "30px",
                    md: "30px",
                    sm: "25px",
                    xs: "22px",
                  },
                  margin: {
                    xl: "50px 0px",
                    lg: "50px 0px",
                    md: "50px 0px",
                    sm: "30px 0px",
                    xs: "30px 0px",
                  },
                }}
              >
                Meet the Our Team
              </Typography>
              <Grid
                sx={{
                  "& .MuiGrid-item": {
                    "&:nth-of-type(1), &:nth-of-type(2)": {
                      flexBasis: {
                        xl: "50%",
                        lg: "50%",
                        md: "50%",
                        sm: "100%",
                        xs: "100%",
                      },
                      maxWidth: {
                        xl: "50%",
                        lg: "50%",
                        md: "50%",
                        sm: "100%",
                        xs: "100%",
                      },
                    },
                    "&:nth-of-type(1)": {
                      display: "flex",
                      justifyContent: {
                        xl: "flex-end",
                        lg: "flex-end",
                        md: "flex-end",
                        sm: "center",
                        xs: "center",
                      },
                      paddingRight: {
                        xl: "120px",
                        lg: "120px",
                        md: "40px",
                        sm: "0",
                        xs: "0",
                      },
                    },
                    "&:nth-of-type(2)": {
                      display: "flex",
                      justifyContent: {
                        xl: "flex-start",
                        lg: "flex-start",
                        md: "flex-start",
                        sm: "center",
                        xs: "center",
                      },
                      paddingLeft: {
                        xl: "170px",
                        lg: "170px",
                        md: "80px",
                        sm: "24px",
                        xs: "24px",
                      },
                    },
                  },
                }}
                container
                spacing={3}
                justifyContent="center"
              >
                {teamMembers?.map((item, index) => {
                  return (
                    <Grid key={index} item lg={4} md={4} sm={12} xs={12}>
                      <Box>
                        <Box
                          sx={{
                            width: "200px",
                            margin: "0 auto",
                            height: "200px",
                            background: "#072035",
                            padding: "5px",
                            borderRadius: "100px",
                            position: "relative",
                            "& img": {
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                              borderRadius: "100px",
                            },
                          }}
                        >
                          <img src={item.dp} />
                          <Box
                            sx={{
                              width: "32px",
                              height: "32px",
                              flex: "0 0 32px",
                              background: "#fff",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "100px",
                              position: "absolute",
                              bottom: "10px",
                              left: "50%",
                              transform: "translateX(-50%)",
                              cursor: "pointer",
                              "& img": {
                                width: "auto",
                                height: "auto",
                                objectFit: "inherit",
                                borderRadius: "0",
                              },
                              "& a": {
                                display: "flex",
                              },
                            }}
                          >
                            <Link to={item.linkedin} target="_blank">
                              <img src={linkedInImage} alt="img" />
                            </Link>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            marginTop: "10px",
                            textAlign: "center",
                            color: "#fff",
                            padding: {
                              lg: "0 40px",
                              md: "0 30px",
                              sm: "0 20px",
                              xs: "0 10px",
                            },
                            "& span": {
                              textAlign: "justify",
                              display: "block",
                              marginTop: "10px",
                            },
                          }}
                        >
                          <Typography>{item.name}</Typography>
                          {/* <Typography variant='h4' sx={{ color: '#b0ffef', fontSize: '14px' }}>{item.title}</Typography> */}
                        </Box>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Container>
          </Box>
        </AnimationOnScroll>
      </Box>
      {isSmallScreen && (
        <Box
          sx={{
            backgroundColor: "#072035",
            padding: "20px",
            borderTop: "1px solid rgb(56, 56, 77)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "25px",
              "& a": {
                display: "flex",
              },
              "& a:hover svg": {
                transform: "rotate(4deg) translateY(-0.1875rem) scale(1.3)",
                color: "#fff",
              },
            }}
          >
            <Link to="tel:+971 567503203" target="_blank">
              <CallIcon sx={{ color: "#807e98", transition: "0.5s ease" }} />
            </Link>
            <Link to={"tel:+971 567503203"} target="_blank">
              <WhatsAppIcon
                sx={{ color: "#807e98", transition: "0.5s ease" }}
              />
            </Link>
            <Link
              to={
                "https://in.linkedin.com/company/1byx?trk=public_profile_experience-item_profile-section-card_image-click"
              }
              target="_blank"
            >
              <LinkedInIcon
                sx={{ color: "#807e98", transition: "0.5s ease" }}
              />
            </Link>
            <Link to={"mailto:manaan@1byx.io"} target="_blank">
              <EmailIcon sx={{ color: "#807e98", transition: "0.5s ease" }} />
            </Link>
          </Box>
        </Box>
      )}

      {!isSmallScreen && <SocialLinks />}
    </Box>
  );
};

export default About;
