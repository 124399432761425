import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";

const Subscribe = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ email });

  const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;

  const isValidEmail = () => {
    const errorObj = {};
    let valid = true;
    if (!email) {
      errorObj.email = "Please enter email address";
      valid = false;
    }
    if (email && !emailRegex.test(email)) {
      errorObj.email = "Please enter valid email address";
      valid = false;
    }
    setError(errorObj); // Set all errors together in the 'error' state.
    return valid;
  };

  const handleSubscribe = async () => {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const isValid = isValidEmail();
    if (isValid) {
      setLoading(true);
      await axios
        .post(`${baseURL}/user/public_subscribe/`, {
          email: email,
        })
        .then((response) => {
          if (response.status === 200) {
            toast.success("Thanks, will connect shortly! ", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  return (
    <Box
      sx={{
        padding: "80px 0",
      }}
    >
      <Container maxWidth="xl">
        <Typography
          sx={{
            fontSize: {
              xl: "28px",
              lg: "28px",
              md: "28px",
              sm: "22px",
              xs: "22px",
            },
            color: "#fff",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          Get In Touch Now
        </Typography>
        <Grid container spacing={0} sx={{ justifyContent: "center" }}>
          <Grid
            item
            lg={8}
            md={8}
            sm={12}
            xs={12}
            sx={{
              backgroundColor: "#072035",
              padding: "40px 20px",
              borderRadius: "10px",
            }}
          >
            <Grid container spacing={2} sx={{ justifyContent: "center" }}>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Box>
                  <TextField
                    sx={{
                      "& fieldset": {
                        borderColor: "#ffffff45 !important",
                        borderRadius: "10px",
                      },
                      ".Mui-error": {
                        "& fieldset": {
                          borderColor: "#d32f2f !important",
                          borderRadius: "10px",
                        },
                      },
                      "& .MuiFormHelperText-root": {
                        marginLeft: "0 !important",
                      },
                      "& input": {
                        color: "#fff",
                        padding: "10px",
                        paddingLeft: "20px",
                      },
                    }}
                    fullWidth
                    placeholder="Your Email"
                    value={email}
                    error={error?.email ? true : false}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setError(false);
                    }}
                    helperText={error?.email && error?.email}
                  />
                </Box>
              </Grid>
            </Grid>
            <Button
              sx={{
                backgroundColor: "#2ec4b6",
                textTransform: "capitalize",
                color: "#fff",
                margin: "30px auto 0px",
                padding: "8px 35px",
                display: "block",
                borderRadius: "10px",
                "&:hover": {
                  backgroundColor: "#ec9721",
                },
              }}
              onClick={handleSubscribe}
            >
              {loading ? (
                <CircularProgress
                  size={14}
                  sx={{ marginRight: "5px" }}
                  color="inherit"
                />
              ) : (
                ""
              )}
              Submit
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Subscribe;
